import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Message({
	variant, message, onClose, bold,
}) {
	return message ? (
		<Alert variant={variant} className={`${bold ? 'font-weight-bold' : ''}`}>
			{message}
			{onClose && (
				<button
					type="button"
					className="close"
					aria-label="Close"
					onClick={onClose}
				>
					<span className="text-danger font-weight-bold" aria-hidden="true">&times;</span>
				</button>
			)}
		</Alert>
	) : null;
}

Message.propTypes = {
	variant: PropTypes.oneOf(['error', 'success', 'info', 'warning']),
	message: PropTypes.string,
	bold: PropTypes.bool,
	onClose: PropTypes.func,
};

export default Message;
