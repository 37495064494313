import React, { useEffect, useState } from 'react';
import {
	Button, Card, Col, Form, FormControl, FormLabel,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMergeState from '../../hooks/use-merge-state';
import * as ROUTES from '../../constants/routes';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import { parseErrorMessage } from '../../utils/parseErrors';
import { getDisplayName } from '../../utils/person';
import { sendGoogleAnalyticsEvent } from '../../utils/googleAnalytics';
import { useDationUser } from '../../hooks/dation-user';
import UserRoleCheckboxInput from '../Form/UserRoleCheckboxInput';
import { COMPANY_ADMIN, COMPANY_SUPER_USER } from '../../constants/roles';
import ErrorMessage from '../common/messages/ErrorMessage';
import { useViewPreferences, VIEW_PREFERENCE_OPTIONS } from '../../hooks/view-preferences';

const EditContactPersonUser = React.memo(({ companyId, companyContactPerson }) => {
	const { t } = useTranslation();
	const toast = useToast();
	const dationUser = useDationUser();
	const navigate = useNavigate();
	const { setPreferences } = useViewPreferences();

	const initialContactPerson = {
		id: null,
		userRole: null,
		companyContactPersonUser: undefined,
		emailAddress: undefined,
		initials: undefined,
		firstName: undefined,
		insertion: undefined,
		lastName: undefined,
		mobileNumber: undefined,
		phoneNumber: undefined,
		department: undefined,
		role: undefined,
		emailPlanning: false,
		emailFinancial: false,
		viewPreferences: VIEW_PREFERENCE_OPTIONS.reduce((accumulator, currentValue) => ({
			...accumulator,
			[currentValue]: true,
		}), {}),
	};

	const [isValidated, setIsValidated] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [showRoleFeedback, setShowRoleFeedback] = useState(false);
	const [contactPerson, setContactPerson] = useMergeState(initialContactPerson);

	const [, updateContactPerson] = useApi.patch(ROUTES.updateCompanyContactPerson(contactPerson?.id));
	const [, postContactPerson] = useApi.post(ROUTES.createCompanyContactPersonUser(companyId));
	const [, updateCompanyContactPersonUser] = useApi.patch(ROUTES.updateCompanyContactPersonUser(contactPerson?.companyContactPersonUser?.id));

	const userType = t('users.type.contact_person');
	const { user } = dationUser || {};
	const { companyContactPerson: currentUserContactPerson = null } = user || {};
	const { emailAddress = null } = currentUserContactPerson || {};
	const isLoggedInUser = contactPerson.emailAddress === emailAddress;

	useEffect(() => {
		if(companyContactPerson !== null) {
			const { companyContactPersonUser = null } = companyContactPerson || {};
			const { roles = [] } = companyContactPersonUser || {};
			setContactPerson({
				...companyContactPerson,
				userRole: roles.length > 0 ? roles[0] : undefined,
			});
		} else {
			setContactPerson(initialContactPerson);
		}
	}, [companyContactPerson]);

	const close = () => {
		setContactPerson(initialContactPerson);
		setErrorMessage(null);
		setIsValidated(false);
		setIsDisabled(false);
	};

	const handleResponse = (eventOptions, successMessage) => {
		sendGoogleAnalyticsEvent(eventOptions);
		close();
		toast.addToast(successMessage);
		navigate(-1);
	};

	const submit = () => {
		const contactPersonData = {
			...contactPerson,
			userRole: [contactPerson.userRole],
		};
		const ccpName = getDisplayName(contactPerson || {});
		let submitContactPerson;
		let updateContactPersonUser;
		let successMessage;
		const eventOptions = {
			hitType: 'event',
			eventCategory: 'CompanyContactPerson',
		};
		const langFields = { userType, name: ccpName };
		if(contactPerson.id) {
			submitContactPerson = updateContactPerson;
			successMessage = t('users.edited', langFields);
			updateContactPersonUser = updateCompanyContactPersonUser;
			eventOptions.eventAction = 'Edit';
		} else {
			eventOptions.eventAction = 'Create';
			submitContactPerson = postContactPerson;
			successMessage = t('users.invite_send', langFields);
		}

		setIsDisabled(true);
		submitContactPerson(contactPersonData)
			.then(() => {
				isLoggedInUser && setPreferences(contactPerson.viewPreferences);
				if(!isLoggedInUser && contactPersonData.companyContactPersonUser && updateContactPersonUser !== undefined) {
					updateContactPersonUser({
						roles: [contactPerson.userRole],
					}).then(() => {
						handleResponse(eventOptions, successMessage);
					}).catch(e => {
						setErrorMessage(parseErrorMessage(e));
						setIsValidated(true);
					});
				} else {
					handleResponse(eventOptions, successMessage);
				}
			})
			.catch(e => {
				setErrorMessage(parseErrorMessage(e));
				setIsValidated(true);
			})
			.finally(() => setIsDisabled(false));
	};

	const handleChange = ({ target }) => {
		const { name, value } = target;
		setContactPerson({ [name]: value });
	};

	const roleRequired = !contactPerson.id || (contactPerson.id && contactPerson.companyContactPersonUser);

	useEffect(() => {
		contactPerson.userRole && setShowRoleFeedback(false);
	}, [contactPerson.userRole]);

	const validateForm = event => {
		event.stopPropagation();
		event.preventDefault();
		if(event.currentTarget.checkValidity() === false) {
			roleRequired && setShowRoleFeedback(true);
			setIsValidated(true);
			setIsDisabled(false);
		} else {
			submit();
		}
	};

	const updateViewPreference = changedPreference => setContactPerson({
		...contactPerson,
		viewPreferences: {
			...contactPerson.viewPreferences,
			[changedPreference]: !contactPerson.viewPreferences[changedPreference],
		},
	});

	return (
		<>
			<ErrorMessage message={errorMessage} />
			<Form noValidate validated={isValidated} onSubmit={validateForm}>
				<Card className="col-12 my-3">
					<Card.Title className="m-3">Algemene gegevens</Card.Title>
					<Card.Body className="col-xl-6">
						<Form.Row className="mb-3">
							<Col xs={12} md={3}>
								<FormLabel>{t('employees.initials')}</FormLabel>
								<FormControl
									id="contact-person-initials"
									type="text"
									name="initials"
									value={contactPerson.initials ?? ''}
									onChange={handleChange}
								/>
							</Col>
							<Col xs={12} md={3}>
								<FormLabel>{t('employees.firstName')}</FormLabel>
								<FormControl
									id="contact-person-first-name"
									type="text"
									name="firstName"
									value={contactPerson.firstName ?? ''}
									onChange={handleChange}
								/>
							</Col>
							<Col xs={12} md={3}>
								<FormLabel>{t('employees.insertion')}</FormLabel>
								<FormControl
									id="contact-person-insertion"
									type="text"
									name="insertion"
									value={contactPerson.insertion ?? ''}
									onChange={handleChange}
								/>
							</Col>
							<Col xs={12} md={3}>
								<FormLabel>{t('employees.lastName')}</FormLabel>
								<FormControl
									id="contact-person-last-name"
									type="text"
									name="lastName"
									value={contactPerson.lastName ?? ''}
									required
									onChange={handleChange}
								/>
								<FormControl.Feedback type="invalid">
									{t('add_employee.field_required')}
								</FormControl.Feedback>
							</Col>
						</Form.Row>

						<Form.Row className="mb-3">
							<Col xs={12} md={3}>
								<FormLabel>{t('employees.phone')}</FormLabel>
								<FormControl
									id="contact-person-mobile-number"
									type="text"
									name="mobileNumber"
									value={contactPerson.mobileNumber ?? ''}
									onChange={handleChange}
								/>
							</Col>
							<Col xs={12} md={3}>
								<FormLabel>{t('employees.phone_fixed')}</FormLabel>
								<FormControl
									id="contact-person-phone-number"
									type="text"
									name="phoneNumber"
									value={contactPerson.phoneNumber ?? ''}
									onChange={handleChange}
								/>
							</Col>

							<Col xs={12} md={6}>
								<FormLabel>{t('employees.email')}</FormLabel>
								<FormControl
									id="contact-person-email"
									type="email"
									name="emailAddress"
									required
									value={contactPerson.emailAddress ?? ''}
									onChange={handleChange}
									disabled={!!contactPerson.companyContactPersonUser}
								/>
								<FormControl.Feedback id="invalid-feedback" type="invalid">
									{t('portal.email_not_valid')}
								</FormControl.Feedback>
							</Col>
						</Form.Row>

						<Form.Row className="mb-3">
							<Col xs={12} md={6}>
								<FormLabel>{t('contact_person.department')}</FormLabel>
								<FormControl
									id="contact-person-department"
									type="text"
									name="department"
									value={contactPerson.department ?? ''}
									onChange={handleChange}
								/>
							</Col>
							<Col xs={12} md={6}>
								<FormLabel>{t('contact_person.function')}</FormLabel>
								<FormControl
									id="contact-person-role"
									type="text"
									name="role"
									value={contactPerson.role ?? ''}
									onChange={handleChange}
								/>
							</Col>
						</Form.Row>

						<Form.Row>
							<Col xs={6}>
								<Form.Check className="mb-3 ml-1" type="checkbox">
									<Form.Check.Input
										type="checkbox"
										id="emailPlanning"
										name="emailPlanning"
										checked={contactPerson.emailPlanning}
										onChange={() => setContactPerson({ emailPlanning: !contactPerson.emailPlanning })}
									/>
									<Form.Check.Label
										htmlFor="emailPlanning"
									>{t('contact_person.planning_emails')}
									</Form.Check.Label>
								</Form.Check>
								<Form.Check className="mb-3 ml-1" type="checkbox">
									<Form.Check.Input
										type="checkbox"
										id="emailFinancial"
										name="emailFinancial"
										checked={contactPerson.emailFinancial}
										onChange={() => setContactPerson({ emailFinancial: !contactPerson.emailFinancial })}
									/>
									<Form.Check.Label
										htmlFor="emailFinancial"
									>{t('contact_person.financial_emails')}
									</Form.Check.Label>
								</Form.Check>
							</Col>
							{roleRequired && (
								<Col xs={6}>
									<FormLabel className="mb-3 ml-1">{t('users.role')}</FormLabel>
									<div>
										<UserRoleCheckboxInput
											label={t(`users.roles.${COMPANY_SUPER_USER.toLowerCase()}`)}
											role={COMPANY_SUPER_USER}
											userRole={contactPerson.userRole}
											onChange={handleChange}
											disabled={isLoggedInUser}
											required
										/>
										<UserRoleCheckboxInput
											label={t(`users.roles.${COMPANY_ADMIN.toLowerCase()}`)}
											role={COMPANY_ADMIN}
											userRole={contactPerson.userRole}
											onChange={handleChange}
											disabled={isLoggedInUser}
										/>
										{showRoleFeedback && (
											<div id="role-invalid-feedback" className="invalid-feedback d-block">
												{t('users.role_required', { userType })}
											</div>
										)}
									</div>
								</Col>
							)}
						</Form.Row>
					</Card.Body>
				</Card>

				<Card className="col-12 my-3">
					<Card.Title className="m-3">Portaal functies</Card.Title>
					<Card.Body className="col-xl-12">
						<p>Het is mogelijk om te kiezen welke onderdelen van het portaal je wilt kunnen zien en
							gebruiken. Standaard wordt alles getoond, maar door hieronder opties uit te vinken kun je er
							voor zorgen dat je alleen voor jou relevante zaken ziet.
						</p>
						{VIEW_PREFERENCE_OPTIONS.map(preference => {
							const { viewPreferences = [] } = contactPerson;
							return (
								<div className="mb-3">
									<Form.Row>
										<Form.Check type="checkbox" inline>
											<Form.Check.Input
												type="checkbox"
												name={preference}
												checked={viewPreferences[preference]}
												onChange={() => updateViewPreference(preference)}
											/>
											<Form.Check.Label onClick={() => updateViewPreference(preference)}>
												{t(`viewPreferences.names.${preference}`)}
											</Form.Check.Label>

										</Form.Check>
									</Form.Row>
									<small className="ml-3">{t(`viewPreferences.helpTexts.${preference}`)}</small>
								</div>
							);
						})}
					</Card.Body>
				</Card>

				<div className="text-right pb-3">
					<Button
						id="save-contact-person"
						variant="primary"
						disabled={isDisabled}
						type="submit"
					>{t('modalActions.edit')}
					</Button>
				</div>
			</Form>
		</>
	);
});

export default EditContactPersonUser;
