/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function GlyphIconChip({
	option, removeChip, updateChip, renderCustomFields = null, disabled = false,
}) {
	const [editableOption, setEditableOption] = useState(option);

	useEffect(() => updateChip(editableOption), [editableOption]);

	const {
		label, value,
	} = editableOption;

	return (
		<div className="row bg-white mx-1 mb-2 py-2 bg-primary shadow-sm">
			<div className="ml-0 col-6">
				<span className="h-100 align-middle">{label}</span>
			</div>
			<div className={`col-6 d-flex ${renderCustomFields ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>
				{renderCustomFields && renderCustomFields({ editableOption, setEditableOption, disabled })}
				<div style={{ width: 70 }} className="text-center">
					<span
						className={`text-center glyphicons glyphicons-bin clickable b-left d-inline-block p-0 chip-glyphicon ${disabled && 'glyphicons-disabled'}`}
						id={`remove-chip-${value}`}
						style={{ cursor: !disabled ? 'pointer' : 'default' }}
						onClick={e => (!disabled ? removeChip(e, editableOption) : '')}
						aria-disabled={disabled}
					/>
				</div>
			</div>
		</div>
	);
}
GlyphIconChip.propTypes = {
	option: PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.string,
		customFields: PropTypes.object,
	}),
	removeChip: PropTypes.func,
	updateChip: PropTypes.func.isRequired,
	renderCustomFields: PropTypes.func,
	disabled: PropTypes.bool,
};

export default GlyphIconChip;
