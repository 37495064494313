import React, { useEffect } from 'react';
import {
	useSortBy, useTable, useGlobalFilter, usePagination,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { COMPANY_TABLE } from '../../constants/tables';
import TableHeader from './Default/TableHeader';
import TableGlobalFilter from './Default/TableGlobalFilter';
import * as ROUTES from '../../constants/routes';
import TableFooter from './Default/TableFooter';
import useUserPreferences from '../../hooks/use-user-preferences';

function CompanyTable({ columns, data, renderCustomFilters }) {
	const { getPageIndex, setPageIndex, getPageSize } = useUserPreferences();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const tableName = COMPANY_TABLE;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state: { pageIndex, pageSize, globalFilter },
		setGlobalFilter,
		page,
		previousPage,
		canPreviousPage,
		nextPage,
		canNextPage,
		gotoPage,
		pageOptions,
		setPageSize,
	} = useTable({
		columns,
		data,
		initialState: {
			pageIndex: getPageIndex(tableName),
			pageSize: getPageSize(tableName),
			sortBy: [
				{
					id: 'name',
					desc: false,
				},
			],
		},
	}, useGlobalFilter, useSortBy, usePagination);

	useEffect(() => {
		setPageIndex(tableName, pageIndex);
	}, [pageIndex]);

	return (
		<div className="col-12 col-xl-10 mx-auto pt-n5">
			<div className="table-responsive overflow-hidden">
				<div className="d-flex justify-content-between align-items-end">
					{renderCustomFilters()}
					<TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
				</div>
				<table {...getTableProps()} className="table table-large">
					<TableHeader headerGroups={headerGroups} />

					<tbody {...getTableBodyProps()} className="table-light">
						{page.length === 0 && (
							<tr>
								<td colSpan={2}>{t('company.none_available')}</td>
							</tr>
						)}
						{page.map(row => {
							prepareRow(row);

							const { original: { id } } = row;
							return (
								<tr
									className="tableRowWhite cursor-pointer"
									{...row.getRowProps()}
									onClick={() => navigate(ROUTES.companyPage(id))}
								>
									{row.cells.map(cell => (
										<td
											className="text-primary-color"
											{...cell.getCellProps()}
										>
											{cell.render('Cell')}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
				<TableFooter
					tableName={tableName}
					previousPage={previousPage}
					canPreviousPage={canPreviousPage}
					nextPage={nextPage}
					canNextPage={canNextPage}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					gotoPage={gotoPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageSizeOptions={[10, 50]}
					totalItems={data.length}
				/>
			</div>
		</div>
	);
}

CompanyTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	renderCustomFilters: PropTypes.func,
};

export default CompanyTable;
