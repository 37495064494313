import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
	usePagination, useSortBy, useTable, useGlobalFilter, useExpanded, useFilters,
} from 'react-table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COURSE_LIST_TABLE } from '../../constants/tables';
import TableHeader from './Default/TableHeader';
import TableFooter from './Default/TableFooter';
import DefaultTableBody from './Default/DefaultTableBody';
import TableGlobalFilter from './Default/TableGlobalFilter';
import useUserPreferences from '../../hooks/use-user-preferences';
import { useDationUser } from '../../hooks/dation-user';
import { transformSlotForSubRow } from '../../utils/training';
import XLSXDownload from '../common/XLSXDownload';
import moment from '../../moment';
import { getDisplayName } from '../../utils/person';
import { getDateFormat, getTimeDateFormat, sortByDate } from '../../utils/date';
import DefaultTableFilter from './Filters/DefaultTableFilter';
import { CERTIFICATES_ENABLED, CODE_95_ENABLED, useViewPreferences } from '../../hooks/view-preferences';

const CourseListTable = forwardRef(({
	columns,
	data,
	handleClick,
	orderDesc = true,
	transformSubRow = transformSlotForSubRow,
	allowExport = true,
}, ref) => {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const {
		getPageIndex, setPageIndex, getPageSize, getFilters, setFilters,
	} = useUserPreferences();

	const { isPreferenceOptionEnabled } = useViewPreferences();

	const tableName = COURSE_LIST_TABLE;

	const defaultColumn = React.useMemo(
		() => ({
			// React table needs a default filter, even though we don't use it at the moment
			Filter: DefaultTableFilter,
		}),
		[],
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		page,
		state: {
			pageIndex, pageSize, globalFilter, filters,
		},
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
		setGlobalFilter,
		setAllFilters,
	} = useTable({
		columns,
		data,
		defaultColumn,
		initialState: {
			pageIndex: getPageIndex(tableName),
			pageSize: getPageSize(tableName),
			sortBy: [
				{
					id: 'startDate',
					desc: orderDesc,
				},
			],
			filters: getFilters(tableName) || [],
		},
	}, useGlobalFilter, useFilters, useSortBy, useExpanded, usePagination);

	useEffect(() => {
		setPageIndex(tableName, pageIndex);
	}, [pageIndex]);

	useEffect(() => {
		setFilters(tableName, filters);
		gotoPage(0);
	}, [filters]);

	// Create a function that will render our row sub components
	const renderRowSubComponent = React.useCallback(
		({ row }) => {
			const { slots } = row.original || [];
			return slots && slots.length > 0 ? slots.map((slot, subRowIndex) => {
				const formattedSlot = transformSubRow(slot);
				return (
					<tr {...row.getRowProps({ key: `${row.id}.subRow${subRowIndex}` })}>
						{Object.keys(formattedSlot).map(key => (
							<td key={`${row.id}-${slot.id}-${key}`} className="text-primary-color">{formattedSlot[key]}</td>
						))}
					</tr>
				);
			}) : null;
		},
		[],
	);

	const prepareXlSX = () => {
		let planningExport = [];
		const sortedPlanningEntries = data.sort((a, b) => sortByDate(a.start, b.start, { order: orderDesc ? 'desc' : 'asc' }));
		sortedPlanningEntries
			.forEach(training => {
				training.slots.forEach(({
					courseName,
					start,
					end,
					location,
					students,
				}) => {
					const companyStudentsEnrollments = training.enrollments.filter(enrollment => enrollment.company.id === dationUser.getCurrentCompanyId());
					const companyStudentEntries = students.reduce(
						(carry, student) => {
							const studentEnrollment = companyStudentsEnrollments.find(enrollment => enrollment.student.id === student.id);
							if(studentEnrollment) {
								const certificateExpirationDate = studentEnrollment?.certificateExpirationDate
									? getDateFormat(studentEnrollment.certificateExpirationDate)
									: '';

								carry.push({
									...(isPreferenceOptionEnabled(CODE_95_ENABLED) && { [t('trainings.export_fields.ccv_code')]: training.ccvCode }),
									[t('trainings.export_fields.course_name')]: training.name,
									[t('trainings.export_fields.slot_name')]: courseName,
									[t('trainings.export_fields.date')]: getDateFormat(start),
									[t('trainings.export_fields.start')]: getTimeDateFormat(start),
									[t('trainings.export_fields.end')]: getTimeDateFormat(end),
									[t('trainings.export_fields.location')]: location,
									[t('trainings.export_fields.student')]: getDisplayName(student),
									...(isPreferenceOptionEnabled(CERTIFICATES_ENABLED) && { [t('trainings.export_fields.certificateExpirationDate')]: certificateExpirationDate }),
								});
							}
							return carry;
						},
						[],
					);
					planningExport = [
						...planningExport,
						...companyStudentEntries,
					];
				});
			});
		return [{
			name: t('navigation.companyUser.planning'),
			rows: planningExport,
		}];
	};

	useImperativeHandle(ref, () => ({
		clearFilters() {
			setAllFilters([]);
		},
	}));

	return (
		<div>
			<div className="table-responsive overflow-hidden">
				<div className="d-flex flex-row-reverse align-items-end">
					<TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
				</div>
				<table {...getTableProps()} className="table table-large">
					<TableHeader headerGroups={headerGroups} customFilters />
					<DefaultTableBody
						getTableBodyProps={getTableBodyProps}
						data={page}
						prepareRow={prepareRow}
						handleClick={handleClick}
						renderRowSubComponent={renderRowSubComponent}
					/>
				</table>
				<TableFooter
					tableName={tableName}
					previousPage={previousPage}
					canPreviousPage={canPreviousPage}
					nextPage={nextPage}
					canNextPage={canNextPage}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					gotoPage={gotoPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageSizeOptions={[10, 50]}
					totalItems={data.length}
					exportButton={(allowExport && data.length > 0) ? (
						<XLSXDownload
							className="mr-3"
							getData={prepareXlSX}
							fileName={`${t('trainings.planningOverview', { description: dationUser.getCompanyName() })} ${moment().format('L')}.xlsx`}
							title={t('table.export')}
							icon
						/>
					) : null}
				/>
			</div>
		</div>
	);
});

CourseListTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	handleClick: PropTypes.func,
	orderDesc: PropTypes.bool,
	transformSubRow: PropTypes.func,
	allowExport: PropTypes.bool,
	onFiltersReset: PropTypes.func,
	resetFiltersDisabled: PropTypes.bool,
};
export default CourseListTable;
