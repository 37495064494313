import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function DefaultTableFilter({
	id,
	children,
	active = false,
}) {
	return (
		<OverlayTrigger
			trigger="click"
			overlay={(
				<Popover id={id} style={{ minWidth: 200 }}>
					<Popover.Content>
						{children}
					</Popover.Content>
				</Popover>
			)}
			placement="top"
			rootClose
		>
			<span className={`text-black-50 glyphicons glyphicons-filter cursor-pointer ${active && 'text-danger'}`} />
		</OverlayTrigger>
	);
}

export default DefaultTableFilter;
