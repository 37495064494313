import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function TableFooterNavigation({
	pageOptions,
	pageIndex,
	gotoPage,
	previousPage,
	canPreviousPage,
	nextPage,
	canNextPage,
	condensed,
}) {
	const { t } = useTranslation();

	return (
		<div className="form-inline">
			{!condensed && (
				<div className="form-inline mx-3">
					<div className="mr-2">{`${t('table.gotoPage')}:`}</div>
					<input
						className="form-control"
						type="number"
						max={pageOptions.length}
						min={1}
						defaultValue={pageIndex + 1 || 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
						}}
					/>
				</div>
			)}

			<div className="btn-group mr-1">
				<button
					className="btn btn-outline-primary"
					type="button"
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
				>
					{t('table.previous')}
				</button>
				<button
					className="btn btn-outline-primary"
					type="button"
					onClick={() => nextPage()}
					disabled={!canNextPage}
				>
					{t('table.next')}
				</button>
			</div>
		</div>
	);
}

TableFooterNavigation.propTypes = {
	pageOptions: PropTypes.array.isRequired,
	pageIndex: PropTypes.number.isRequired,
	gotoPage: PropTypes.func.isRequired,
	previousPage: PropTypes.func.isRequired,
	canPreviousPage: PropTypes.bool.isRequired,
	nextPage: PropTypes.func.isRequired,
	canNextPage: PropTypes.bool.isRequired,
	condensed: PropTypes.bool.isRequired,
};
export default TableFooterNavigation;
