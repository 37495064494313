import React from 'react';
import PropTypes from 'prop-types';

function DefaultTableBody({
	getTableBodyProps,
	data = null,
	prepareRow,
	handleClick = null,
	defaultClassName = 'tableRowWhite',
	renderRowSubComponent,
	emptyMessage,
}) {
	const renderRow = row => {
		prepareRow(row);
		const { original } = row;
		return (
			<React.Fragment key={original.id}>
				<tr
					{...row.getRowProps({
						className: `${defaultClassName} ${!!handleClick && 'cursor-pointer'}`,
					})}
				>
					{row.cells.map(cell => (
						<td
							key={cell.index}
							role="gridcell"
							{...cell.getCellProps({
								className: `text-primary-color ${cell.column.id === 'expander' ? 'text-center' : ''}`,
							})}
							onClick={() => {
								if(handleClick && cell.column.id !== 'expander') {
									handleClick(original);
								}
							}}
						>{cell.render('Cell')}
						</td>
					))}
				</tr>
				{row.isExpanded ? renderRowSubComponent({ row }) : null}
			</React.Fragment>
		);
	};

	return (
		<tbody {...getTableBodyProps()} className="table-light">
			{emptyMessage && !data.length && (
				<tr>
					<td colSpan={2}>{emptyMessage}</td>
				</tr>
			)}
			{data.map(row => renderRow(row))}
		</tbody>
	);
}

DefaultTableBody.propTypes = {
	getTableBodyProps: PropTypes.func.isRequired,
	data: PropTypes.array,
	prepareRow: PropTypes.func.isRequired,
	handleClick: PropTypes.func,
	defaultClassName: PropTypes.string,
	renderRowSubComponent: PropTypes.func,
	emptyMessage: PropTypes.string,
};

export default DefaultTableBody;
