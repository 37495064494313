/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
	Button, Modal, ModalBody, ModalFooter, ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDationUser } from '../../hooks/dation-user';
import { enrollCompanyStudents } from '../../constants/routes';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import { sendGoogleAnalyticsEvent } from '../../utils/googleAnalytics';
import { getDateFormat, getWeekdayFormat } from '../../utils/date';
import Slots from './PlannedTrainingModal/Slots';
import ErrorMessage from '../common/messages/ErrorMessage';
import StudentsSelection from '../Employees/StudentsSelection';
import {
	findExistingReservation, getCalculatedReservationAmounts, getPlanCapacity,
} from '../../utils/training';
import useCheckStudentEnrollments from '../../hooks/use-check-student-enrollments';
import StudentWarnings from '../Employees/StudentWarnings';

function EnrollEmployeeModal({
	show, setShow, training = null, handleSubmit,
}) {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const toast = useToast();

	const [errorMessages, setErrorMessages] = useState([]);
	const [isDisabled, setDisabled] = useState(false);
	const {
		studentWarnings, setStudentWarnings, selectedStudents, setSelectedStudents, removeWarning,
	} = useCheckStudentEnrollments(training);

	const [, postCompanyEnrollments] = useApi.post(enrollCompanyStudents(training?.id));

	const [message, setMessage] = useState(null);
	const [trainingReservation, setTrainingReservation] = useState(null);

	useEffect(() => {
		// filter out students that are already enrolled on the training
		if(training !== null) {
			setTrainingReservation(findExistingReservation(training.trainingReservations, dationUser.getCurrentCompanyId()) || {});
		}
	}, [training]);

	const cancel = () => {
		setSelectedStudents([]);
		setErrorMessages([]);
		setMessage('');
		setShow(false);
		setStudentWarnings([]);
	};

	const submit = e => {
		e.preventDefault();
		e.stopPropagation();
		setDisabled(true);
		const enrollmentDetails = selectedStudents.map(({ value, customFields }) => ({
			student: { id: value },
			company: { id: dationUser.getCurrentCompanyId() },
			code95: customFields.ccv,
			soob: customFields.soob,
		}));
		const enrollmentData = {
			enrollmentDetails,
			company: { id: dationUser.getCurrentCompanyId() },
			message,
		};
		postCompanyEnrollments(enrollmentData)
			.then(() => {
				sendGoogleAnalyticsEvent({
					hitType: 'event',
					eventCategory: 'Employee',
					eventAction: 'Enroll',
					eventLabel: `${selectedStudents.length} employee(s) enrolled`,
				});
				setShow(false);
				setDisabled(false);
				handleSubmit();

				setSelectedStudents([]);
				setErrorMessages([]);
				setMessage('');
				toast.addToast(t('trainings.enrolled_employees'));
			})
			.catch(err => {
				setErrorMessages(err);
				setMessage('');
				setDisabled(false);
			});
	};

	const { slots } = training || [];

	const disabledActionsOnError = errorMessages.length > 0;
	const { amountPlanned, amountReserved = null } = trainingReservation ? getCalculatedReservationAmounts(trainingReservation, dationUser.getCurrentCompanyId()) : {};

	return (
		<Modal show={show} onHide={() => cancel()} size="lg">
			<Modal.Header className="d-flex align-items-center" closeButton>
				<ModalTitle>{t('trainings.enroll')}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				{errorMessages.length > 0 && errorMessages.map(errorMessage => (
					<ErrorMessage message={errorMessage} />
				))}
				<dl className="row">
					<dt className="col-3 text-primary-color">{t('trainings.course')}</dt>
					<dd className="col-9">{training?.name}</dd>

					<dt className="col-3 text-primary-color">{t('trainings.date')}</dt>
					<dd className="col-8">
						<div className="row">
							<div className="col-1">{getWeekdayFormat(training?.start)}</div>
							<div className="col-5 pl-0 d-flex flex-row">
								<div>{getDateFormat(training?.start)}</div>
							</div>
						</div>
					</dd>

					{slots && slots.length > 0
					&& <Slots titleClassnames="col-3" descriptionClassnames="col-8" slots={slots} />}

					<dt className="col-3 text-primary-color">{t('trainings.location')}</dt>
					<dd className="col-9">{training?.city}</dd>

					<dt className="col-3 text-primary-color">{t('trainings.capacity')}</dt>
					<dd className="col-9">{getPlanCapacity(training, trainingReservation)}</dd>

					{amountReserved > 0 && (
						<>
							<dt className="col-3 text-primary-color">{t('trainings.reservation')}</dt>
							<dd className="col-9">{t('trainings.reservation_amounts', {
								amountPlanned,
								amountReserved,
							})}
							</dd>
						</>
					)}
				</dl>

				<StudentWarnings warnings={studentWarnings} onRemove={removeWarning} />

				<StudentsSelection
					show={show}
					training={training}
					selected={selectedStudents}
					setSelected={setSelectedStudents}
					maxSelected={getPlanCapacity(training, trainingReservation)}
					message={message}
					setMessage={setMessage}
					disabled={disabledActionsOnError || isDisabled}
				/>
			</ModalBody>
			<ModalFooter>
				{errorMessages.length > 0 ? (
					<Button variant="outline-secondary" onClick={() => cancel()}>{t('modalActions.close')}</Button>
				) : (
					<>
						<Button
							variant="outline-secondary"
							disabled={isDisabled}
							onClick={() => cancel()}
						>{t('modalActions.cancel')}
						</Button>
						<Button
							variant="primary"
							disabled={isDisabled || selectedStudents.length === 0}
							onClick={submit}
						>{t('modalActions.enroll')}
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
}

export default EnrollEmployeeModal;
