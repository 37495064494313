import React from 'react';
import PropTypes from 'prop-types';
import { getXLSXFile } from '../../utils/xlsx';

function XLSXDownload({
	getData, fileName, title, icon = false, className = '',
}) {
	return (
		<button
			type="button"
			onClick={() => getXLSXFile(getData(), fileName)}
			className={`btn btn-outline-secondary d-flex align-items-center ${className}`}
		>
			{icon && <span className="glyphicons glyphicons-cloud-download mr-1" />}
			{title}
		</button>
	);
}

XLSXDownload.propTypes = {
	getData: PropTypes.func.isRequired,
	fileName: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	icon: PropTypes.bool,
};
export default XLSXDownload;
