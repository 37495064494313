import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DateInput from '../components/Form/DateInput';
import InfoBar from '../components/Navigation/InfoBar';
import SmallFormLabel from '../components/Form/SmallFormLabel';
import useApi from '../hooks/use-api';
import * as ROUTES from '../constants/routes';
import { getStartOfDayFullDateFormat } from '../utils/date';

function StatisticsPage() {
	const { t } = useTranslation();

	const [start, setStart] = useState(moment());
	const [end, setEnd] = useState(moment().add(1, 'month'));
	const [shouldDownload, setShouldDownLoad] = useState(false);

	const [{ data }, fetchStatistics] = useApi.get(
		ROUTES.getEnrollmentStatistics(),
		{ start: getStartOfDayFullDateFormat(start), end: getStartOfDayFullDateFormat(end) },
		{ lazy: true, cachePolicy: 'no-cache' },
	);

	useEffect(() => {
		if(shouldDownload && data !== null) {
			const download = document.createElement('a');
			download.href = `data:application/xlsx;base64,${data.content}`;
			download.download = `${t('statistics.fileName')} ${start.format('DD-MM-YYYY')}-${end.format('DD-MM-YYYY')}.xlsx`;
			download.click();
			setShouldDownLoad(false);
		}
	}, [data]);
	return (
		<>
			<InfoBar />
			<Form.Row className="mb-3">
				<Col xs={2}>
					<SmallFormLabel label={t('statistics.startDate')} />
					<DateInput onChange={newStart => setStart(newStart)} value={start} />
				</Col>
			</Form.Row>
			<Form.Row className="mb-3">
				<Col xs={2}>
					<SmallFormLabel label={t('statistics.endDate')} />
					<DateInput onChange={newEnd => setEnd(newEnd)} value={end} />
				</Col>
			</Form.Row>
			<Form.Row className="mb-3">
				<Col xs={2}>
					<Button
						variant="primary"
						block
						onClick={() => {
							setShouldDownLoad(true);
							fetchStatistics();
						}}
					>
						{t('statistics.download')}
					</Button>
				</Col>
			</Form.Row>
		</>
	);
}

export default StatisticsPage;
