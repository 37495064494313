import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getDateFormat } from '../../utils/date';

function ChecklistOption({ data: { value } }) {
	const getChecklistOptionVariant = () => {
		// editable checkbox shows as selected and null if not
		if(value.checked !== undefined) {
			return value.checked ? <span className="glyphicons glyphicons-square-check text-success pr-1" /> : null;
		}

		if(!value.examDate) {
			return null;
		}

		const formattedExamDate = getDateFormat(value.examDate);
		if(moment(value.examDate).isAfter(moment())) {
			// Item planned in future: show calendar icon
			return <><span className="glyphicons glyphicons-calendar text-success pr-1" /><span>{formattedExamDate}</span></>;
		}
		if(value.examResult !== null) {
			return value.examResult
				? <><span className="glyphicons glyphicons-square-check text-success pr-1" /><span>{formattedExamDate}</span></>// Student passed exam
				: <><span className="glyphicons glyphicons-square-remove text-danger pr-1" /><span>{formattedExamDate}</span></>;// Student failed exam
		}
		return null;
	};

	return <span className="d-flex flex-row align-items-center">{getChecklistOptionVariant()}</span>;
}

ChecklistOption.propTypes = {
	data: PropTypes.shape({
		position: PropTypes.number,
		option: PropTypes.shape({
			type: PropTypes.string,
			name: PropTypes.string,
		}),
		value: PropTypes.object,
	}).isRequired,
};

export default ChecklistOption;
