import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { INFO_TOOLTIP_HIDE_DELAY } from '../../constants/componentDefaults';

export const infoIcon = () => (
	<span
		style={{ width: 20, transform: 'translateY(1px)' }}
		className="text-center text-secondary glyphicons glyphicons-circle-info clickable b-left d-inline-block p-0 chip-glyphicon"
	/>
);

const Tooltip = React.memo(({
	children, renderPopoverContent, renderPopoverTitle = null, hideDelay = INFO_TOOLTIP_HIDE_DELAY,
}) => {
	const renderPopover = (
		<Popover id="popover-basic">
			<Popover.Title>
				{renderPopoverTitle && renderPopoverTitle()}
			</Popover.Title>
			<Popover.Content>
				{renderPopoverContent()}
			</Popover.Content>
		</Popover>
	);
	return (
		<OverlayTrigger
			key="soob-info-trigger"
			placement="top"
			delay={{ hide: hideDelay }}
			overlay={renderPopover}
		>
			{children}
		</OverlayTrigger>
	);
});

Tooltip.propTypes = {
	children: PropTypes.node.isRequired,
	renderPopoverContent: PropTypes.func.isRequired,
	renderPopoverTitle: PropTypes.func,
	hideDelay: PropTypes.number,
};
export default Tooltip;
