/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
	Modal, Form, ModalBody, ModalTitle, FormLabel, Button, ModalFooter,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useMergeState from '../../hooks/use-merge-state';
import useApi from '../../hooks/use-api';
import { dationInstanceEndpoint } from '../../constants/routes';
import { useToast } from '../../hooks/use-toast';
import { parseErrorMessage } from '../../utils/parseErrors';
import ErrorMessage from '../common/messages/ErrorMessage';

function AddDationInstanceModal({ show, setShow, handleSubmit }) {
	const { t } = useTranslation();
	const toast = useToast();
	const [errorMessage, setErrorMessage] = useState(null);
	const [validated, setValidated] = useState(false);

	const [instance, setInstance] = useMergeState({});

	const [state, postDationInstance] = useApi.post(dationInstanceEndpoint());

	const addDationInstance = event => {
		event.preventDefault();
		event.stopPropagation();

		if(event.currentTarget.checkValidity() !== false) {
			setInstance({});
			postDationInstance(instance)
				.then(() => {
					setShow(false);
					handleSubmit();
					toast.addToast(t('dation_instance.add_success'));
				})
				.catch(error => {
					setErrorMessage(parseErrorMessage(error));
				});
		} else {
			setValidated(true);
		}
	};

	return (
		<Modal show={show} onHide={() => setShow(false)} size="lg">
			<Form noValidate validated={validated} onSubmit={addDationInstance}>
				<Modal.Header closeButton>
					<ModalTitle>{t('dation_instance.add')}</ModalTitle>
				</Modal.Header>
				<ModalBody>
					<ErrorMessage message={errorMessage} />
					<Form.Row>
						<Form.Group className="col">
							<FormLabel>{t('dation_instance.name')}</FormLabel>
							<Form.Control
								id="dationName"
								type="text"
								required
								value={instance.name}
								onChange={e => setInstance({ name: e.target.value })}
							/>
							<Form.Control.Feedback type="invalid">
								{t('add_employee.field_required')}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className="col">
							<FormLabel>{t('dation_instance.email_address')}</FormLabel>
							<Form.Control
								type="email"
								value={instance.emailAddress}
								required
								onChange={e => setInstance({ emailAddress: e.target.value })}
							/>
							<Form.Control.Feedback type="invalid">
								{t('add_employee.field_required')}
							</Form.Control.Feedback>
						</Form.Group>

					</Form.Row>

					<Form.Row>
						<Form.Group className="col-4">
							<FormLabel>{t('dation_instance.handle')}</FormLabel>
							<Form.Control
								id="handle"
								type="text"
								required
								value={instance.handle}
								onChange={e => setInstance({ handle: e.target.value })}
							/>
							<Form.Control.Feedback type="invalid">
								{t('add_employee.field_required')}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className="col-4">
							<FormLabel>{t('dation_instance.region_name')}</FormLabel>
							<Form.Control
								id="regionName"
								type="text"
								required
								value={instance.regionName}
								onChange={e => setInstance({ regionName: e.target.value })}
							/>
							<Form.Control.Feedback type="invalid">
								{t('add_employee.field_required')}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className="col-4">
							<FormLabel>{t('dation_instance.phone_number')}</FormLabel>
							<Form.Control
								id="phoneNumber"
								type="text"
								value={instance.phoneNumber}
								onChange={e => setInstance({ phoneNumber: e.target.value })}
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group className="col">
							<FormLabel>{t('dation_instance.dation_id')}</FormLabel>
							<Form.Control
								id="dationId"
								type="number"
								required
								value={instance.dationId}
								onChange={e => setInstance({ dationId: parseInt(e.target.value, 10) })}
							/>
							<Form.Control.Feedback type="invalid">
								{t('add_employee.field_required')}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className="col">
							<FormLabel>{t('dation_instance.api_id')}</FormLabel>
							<Form.Control
								id="apiId"
								type="text"
								required
								value={instance.apiId}
								onChange={e => setInstance({ apiId: e.target.value })}
							/>
							<Form.Control.Feedback type="invalid">
								{t('add_employee.field_required')}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className="col">
							<FormLabel>{t('dation_instance.administration_id')}</FormLabel>
							<Form.Control
								id="administrationId"
								type="number"
								required
								value={instance.administrationId}
								onChange={e => setInstance({ administrationId: parseInt(e.target.value, 10) })}
							/>
							<Form.Control.Feedback type="invalid">
								{t('add_employee.field_required')}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
				</ModalBody>
				<ModalFooter>
					<br />
					<Button variant="outline-secondary" onClick={() => setShow(false)}>{t('dation_instance.cancel')}</Button>
					<Button
						variant="primary"
						type="submit"
					>{t('dation_instance.add_submit')}
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
}

export default AddDationInstanceModal;
