/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalTitle,
	ModalBody,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
	Col,
	Row,
	Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DateInput from '../Form/DateInput';
import useMergeState from '../../hooks/use-merge-state';
import * as ROUTES from '../../constants/routes';
import useApi from '../../hooks/use-api';
import { useDationUser } from '../../hooks/dation-user';
import { useToast } from '../../hooks/use-toast';
import moment from '../../moment';
import { parseErrorMessage } from '../../utils/parseErrors';
import { sendGoogleAnalyticsEvent } from '../../utils/googleAnalytics';
import ErrorMessage from '../common/messages/ErrorMessage';
import AddNewCycle from '../Code95/AddNewCycle';
import {
	CODE_95_ENABLED, DRIVING_PASS_EXPIRY_DATE_ENABLED,
	LICENSE_EXPIRY_DATE_ENABLED,
	useViewPreferences,
} from '../../hooks/view-preferences';

function StudentModal({
	show,
	setShow,
	employeeData,
	handleSubmit,
	establishmentData,
}) {
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const toast = useToast();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const initialState = {
		initials: undefined,
		firstName: undefined,
		officialFirstName: undefined,
		insertion: undefined,
		lastName: undefined,
		phoneNumber: undefined,
		emailAddress: undefined,
		dateOfBirth: undefined,
		placeOfBirth: undefined,
		comments: undefined,
		employeeNumber: undefined,
		cbrId: undefined,
		drivingLicenseExpiryDate: undefined,
		driverPassExpiryDate: undefined,
		code95Student: isPreferenceOptionEnabled(CODE_95_ENABLED),
	};

	const [isValidated, setValidated] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isDisabled, setDisabled] = useState(false);
	const [cycleStart, setCycleStart] = useState(null);
	const [state, mergeState] = useMergeState(initialState);

	const [, postEmployee] = useApi.post(ROUTES.addEmployee);
	const [, updateEmployee] = useApi.patch(ROUTES.editEmployee(employeeData?.id));
	const [, postCode95Cycle] = useApi.post(ROUTES.postCode95Cycle());

	useEffect(() => {
		mergeState({
			company: {
				id: dationUser.getCurrentCompanyId(),
			},
		});
	}, [dationUser]);

	useEffect(() => {
		if(employeeData) {
			const tempEmployeeData = { ...employeeData };
			delete tempEmployeeData.company;
			mergeState({
				...tempEmployeeData,
				dateOfBirth: employeeData.dateOfBirth ? moment(employeeData.dateOfBirth) : undefined,
				driverPassExpiryDate: employeeData.driverPassExpiryDate ? moment(employeeData.driverPassExpiryDate) : undefined,
				drivingLicenseExpiryDate: employeeData.drivingLicenseExpiryDate ? moment(employeeData.drivingLicenseExpiryDate) : undefined,
			});
		}
	}, [employeeData, show, mergeState]);

	const submit = event => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;

		setDisabled(true);

		if(form.checkValidity() !== false) {
			const data = {
				...state,
				comments: state.comments ? state.comments.trim() : null,
				dateOfBirth: moment.isMoment(state.dateOfBirth) ? state.dateOfBirth.format() : null,
				drivingLicenseExpiryDate: moment.isMoment(state.drivingLicenseExpiryDate) ? state.drivingLicenseExpiryDate.format() : null,
				driverPassExpiryDate: moment.isMoment(state.driverPassExpiryDate) ? state.driverPassExpiryDate.format() : null,
			};

			Object.keys(data).forEach(key => {
				if(data[key] === '') {
					data[key] = null;
				}
			});

			let submitFunction;
			let successMessage;
			let eventOptions = null;

			if(employeeData) {
				submitFunction = updateEmployee;
				successMessage = t('employees.edited');
			} else {
				eventOptions = {
					hitType: 'event',
					eventCategory: 'Employee',
					eventAction: 'Create',
				};
				submitFunction = postEmployee;
				successMessage = t('employees.added');
			}

			submitFunction(data)
				.then(result => {
					if(eventOptions) {
						sendGoogleAnalyticsEvent(eventOptions);
					}
					if(moment.isMoment(cycleStart)) {
						const newCycle = {
							startDate: cycleStart.format(),
							endDate: cycleStart.clone().add(5, 'years').subtract('1', 'day').format(),
							student: {
								id: result.id,
							},
						};

						postCode95Cycle(newCycle).then(() => {
							toast.addToast(successMessage);
						});
					} else {
						toast.addToast(successMessage);
					}
					setShow(false);
					handleSubmit(result.id);
					setDisabled(false);
					mergeState(initialState);
					setValidated(false);
				})
				.catch(e => {
					setErrorMessage(parseErrorMessage(e));
					setDisabled(false);
				});
		} else {
			setDisabled(false);
			setValidated(true);
		}
	};

	const handleEstablishmentChange = e => {
		mergeState({ establishment: establishmentData.find(establishment => establishment.id === parseInt(e.target.value, 10)) || null });
	};

	const cancel = () => {
		mergeState(initialState);
		setShow(false);
		setValidated(false);
	};

	return (
		<Modal show={show} onHide={() => cancel()} size="lg">
			<Form noValidate validated={isValidated} onSubmit={submit}>
				<Modal.Header closeButton={!isDisabled}>
					<ModalTitle>{employeeData ? t('employees.edit') : t('employees.add')}</ModalTitle>
				</Modal.Header>
				<ModalBody>
					<ErrorMessage message={errorMessage} />
					<Form.Row className="mb-3">
						<Col xs={12} md={3}>
							<FormLabel>{t('employees.initials')}</FormLabel>
							<FormControl
								type="text"
								value={state.initials ?? ''}
								onChange={e => mergeState({ initials: e.target.value })}
							/>
						</Col>
						<Col xs={12} md={3}>
							<FormLabel>{t('employees.firstName')}</FormLabel>
							<FormControl
								type="text"
								value={state.firstName ?? ''}
								onChange={e => mergeState({ firstName: e.target.value })}
								required
							/>
							<FormControl.Feedback type="invalid">
								{t('add_employee.field_required')}
							</FormControl.Feedback>
						</Col>
						<Col xs={12} md={3}>
							<FormLabel>{t('employees.insertion')}</FormLabel>
							<FormControl
								type="text"
								value={state.insertion ?? ''}
								onChange={e => mergeState({ insertion: e.target.value })}
							/>
						</Col>
						<Col xs={12} md={3}>
							<FormLabel>{t('employees.lastName')}</FormLabel>
							<FormControl
								type="text"
								value={state.lastName ?? ''}
								required
								onChange={e => mergeState({ lastName: e.target.value })}
							/>
							<FormControl.Feedback type="invalid">
								{t('add_employee.field_required')}
							</FormControl.Feedback>
						</Col>
					</Form.Row>

					<Form.Row className="mb-3">
						<Col xs={12} md={4}>
							<FormLabel>{t('employees.officialFirstName')}</FormLabel>
							<FormControl
								type="text"
								value={state.officialFirstName ?? ''}
								required
								onChange={e => mergeState({ officialFirstName: e.target.value })}
							/>
							<FormControl.Feedback type="invalid">
								{t('add_employee.field_required')}
							</FormControl.Feedback>
						</Col>
						<Col xs={12} md={4}>
							<FormLabel>{t('employees.dateOfBirth')}</FormLabel>
							<DateInput onChange={dateOfBirth => mergeState({ dateOfBirth })} value={state.dateOfBirth} required />
							<Form.Control type="text" className="d-none" value={state.dateOfBirth} required />
							<FormControl.Feedback type="invalid">
								{t('add_employee.field_required')}
							</FormControl.Feedback>
						</Col>
						<Col xs={12} md={4}>
							<FormLabel>{t('employees.placeOfBirth')}</FormLabel>
							<FormControl
								type="text"
								value={state.placeOfBirth ?? ''}
								onChange={e => mergeState({ placeOfBirth: e.target.value })}
							/>
						</Col>
					</Form.Row>

					<Form.Row className="mb-3">
						<Col xs={12} md={4}>
							<FormLabel>{t('employees.cbrNumber')}</FormLabel>
							<FormControl
								type="text"
								value={state.cbrId ?? ''}
								pattern="^[0-9]{1,10}$"
								onChange={e => mergeState({ cbrId: e.target.value })}
							/>
							<FormControl.Feedback type="invalid">
								{t('add_employee.max_10_digits', { amount: 10 })}
							</FormControl.Feedback>
						</Col>
						<Col xs={12} md={4}>
							<FormLabel>{t('employees.employeeNumber')}</FormLabel>
							<FormControl
								type="text"
								value={state.employeeNumber ?? ''}
								maxLength={20}
								onChange={e => mergeState({ employeeNumber: e.target.value })}
							/>
							<FormControl.Feedback type="invalid">
								{t('add_employee.max_10_digits', { amount: 20 })}
							</FormControl.Feedback>
						</Col>
						{establishmentData?.length > 0 && (
							<Col xs={12} md={4}>
								<FormLabel>{t('employees.establishment')}</FormLabel>
								<select
									className="form-control"
									value={state.establishment?.id}
									onChange={handleEstablishmentChange}
								>
									<option value={null}>-</option>
									{establishmentData.map(establishment => (
										<option
											key={establishment.id}
											value={establishment.id}
										>{establishment.name}
										</option>
									))}
								</select>
							</Col>
						)}
					</Form.Row>

					<Form.Row className="mb-3">
						<Col xs={12} md={6}>
							<FormLabel>{t('employees.phone')}</FormLabel>
							<FormControl
								type="text"
								value={state.phoneNumber ?? ''}
								onChange={e => mergeState({ phoneNumber: e.target.value })}
							/>
							<FormControl.Feedback type="invalid">
								{t('add_employee.invalid_phone_number')}
							</FormControl.Feedback>
						</Col>
						<Col xs={12} md={6}>
							<FormLabel>{t('employees.email')}</FormLabel>
							<FormControl
								type="email"
								value={state.emailAddress ?? ''}
								required
								onChange={e => mergeState({ emailAddress: e.target.value })}
							/>
							<FormControl.Feedback type="invalid">
								{t('portal.email_not_valid')}
							</FormControl.Feedback>
						</Col>
					</Form.Row>
					<Form.Row className="mb-3">
						<Col xs={12}>
							<FormLabel>{t('employees.comments')}</FormLabel>
							<FormControl
								as="textarea"
								id="comments"
								rows={3}
								value={state.comments ?? ''}
								onChange={e => mergeState({ comments: e.target.value })}
							/>
						</Col>
					</Form.Row>
					<Form.Row className="mb-3">
						{isPreferenceOptionEnabled(LICENSE_EXPIRY_DATE_ENABLED) && (
							<Col xs={12} md={6}>
								<Row>
									<Col xs={12} md={9}>
										<FormLabel>{t('employees.drivingLicenseExpirationDate')}</FormLabel>
										<DateInput
											onChange={drivingLicenseExpiryDate => mergeState({ drivingLicenseExpiryDate })}
											value={state.drivingLicenseExpiryDate}
										/>
									</Col>
								</Row>
							</Col>
						)}
						{isPreferenceOptionEnabled(DRIVING_PASS_EXPIRY_DATE_ENABLED) && (
							<Col xs={12} md={6}>
								<Row>
									<Col xs={12} md={9}>
										<FormLabel>{t('employees.driverPassExpirationDate')}</FormLabel>
										<DateInput
											onChange={driverPassExpiryDate => mergeState({ driverPassExpiryDate })}
											value={state.driverPassExpiryDate}
										/>
									</Col>
								</Row>
							</Col>
						)}
					</Form.Row>
					{(isPreferenceOptionEnabled(CODE_95_ENABLED) && !state.id) && (
						<AddNewCycle
							code95Student={state.code95Student}
							cycleStart={cycleStart}
							updateStudent={mergeState}
							setCycleStart={setCycleStart}
						/>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="outline-secondary"
						disabled={isDisabled}
						onClick={() => cancel()}
					>{t('employees.cancel')}
					</Button>
					<Button
						variant="primary"
						disabled={isDisabled}
						type="submit"
					>{employeeData ? t('modalActions.edit') : t('modalActions.add')}
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
}

StudentModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	employeeData: PropTypes.shape({
		id: PropTypes.number,
		initials: PropTypes.string,
		firstName: PropTypes.string,
		insertion: PropTypes.string,
		lastName: PropTypes.string.isRequired,
		phoneNumber: PropTypes.string,
		emailAddress: PropTypes.string,
		dateOfBirth: PropTypes.string,
		cbrId: PropTypes.string,
		drivingLicenseExpiryDate: PropTypes.string,
		driverPassExpiryDate: PropTypes.string,
		company: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
	}),
	handleSubmit: PropTypes.func,
	establishmentData: PropTypes.array,
};
export default StudentModal;
