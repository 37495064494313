import React, { useContext } from 'react';
import { AccordionContext, Button, useAccordionToggle } from 'react-bootstrap';
import { getDateFormat, getTimeDateFormat, getWeekdayFormat } from '../../../utils/date';

function SlotHeader({ slot, eventKey }) {
	const currentEventKey = useContext(AccordionContext);
	const decoratedOnClick = useAccordionToggle(eventKey);

	return (
		<div className="row border-0 p-1" key={slot.id} style={{ backgroundColor: 'transparent' }}>
			<div className="col-2 col-md-1 pr-0">{getWeekdayFormat(slot.start)}</div>
			<div className="col-8 col-md-3 p-0 d-flex flex-row ">
				<div className="pl-1">{getDateFormat(slot.start)}</div>
				<div className="pl-2">{`${getTimeDateFormat(slot.start)}  -  ${getTimeDateFormat(slot.end)}`}</div>
			</div>
			<Button variant="link" size="sm" className="p-0" onClick={decoratedOnClick}>
				<span className={`glyphicons glyphicons-chevron-${currentEventKey === eventKey ? 'up' : 'down'}`} />
			</Button>
		</div>
	);
}

export default SlotHeader;
