import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useTable,
	useSortBy,
	usePagination,
	useGlobalFilter,
	useFilters,
} from 'react-table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { COMPANY_STUDENT_TABLE } from '../../constants/tables';
import TableFooter from './Default/TableFooter';
import XLSXDownload from '../common/XLSXDownload';
import * as ROUTES from '../../constants/routes';
import TableHeader from './Default/TableHeader';
import TableGlobalFilter from './Default/TableGlobalFilter';
import useUserPreferences from '../../hooks/use-user-preferences';
import DefaultTableFilter from './Filters/DefaultTableFilter';

function CompanyStudentTable({
	columns,
	data,
	renderCustomFilters,
	showExportButton = false,
	prepareExportData,
	sortByProps = { id: 'lastName', desc: false },
}) {
	const {
		getPageIndex, setPageIndex, getPageSize, getFilters, setFilters,
	} = useUserPreferences();
	const tableName = COMPANY_STUDENT_TABLE;

	const defaultColumn = React.useMemo(
		() => ({
			// React table needs a default filter, even though we don't use it at the moment
			Filter: DefaultTableFilter,
		}),
		[],
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		page,
		state: {
			pageIndex, pageSize, globalFilter, filters,
		},
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
		setGlobalFilter,
		setFilter,
		setAllFilters,
	} = useTable({
		columns,
		data,
		defaultColumn,
		initialState: {
			pageIndex: getPageIndex(tableName),
			pageSize: getPageSize(tableName),
			sortBy: [sortByProps],
			filters: getFilters(tableName) || [],
			hiddenColumns: ['establishmentId'],
		},
	}, useGlobalFilter, useFilters, useSortBy, usePagination);

	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		setPageIndex(tableName, pageIndex);
	}, [pageIndex]);

	useEffect(() => {
		setFilters(tableName, filters);
		gotoPage(0);
	}, [filters]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-end">
				<div className="d-flex form-group">
					{renderCustomFilters && (
						<>
							{renderCustomFilters({ filters, setFilter })}
							{filters.length > 0 && (
								<button
									type="button"
									className="btn btn-outline-secondary text-nowrap ml-3"
									onClick={() => setAllFilters([])}
								>
									{t('table.clearFilters')}
								</button>
							)}
						</>
					)}
				</div>
				<TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
			</div>
			<div className="table-responsive overflow-hidden">
				<table {...getTableProps()} className="table table-large">
					<TableHeader headerGroups={headerGroups} customFilters />
					<tbody {...getTableBodyProps()} className="table-light">
						{!page.length && (
							<tr>
								<td colSpan={columns.length}>{t('employees.none_available')}</td>
							</tr>
						)}
						{page.map(row => {
							prepareRow(row);
							// Get the id from the original dataset for the row
							const { original: { id } } = row;
							return (
								<tr
									className="tableRowWhite cursor-pointer"
									{...row.getRowProps()}
									onClick={() => navigate(ROUTES.employeeDetailPage(id))}
								>
									{row.cells.map(cell => (
										<td
											className="text-primary-color"
											{...cell.getCellProps()}
										>{cell.render('Cell')}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
				<TableFooter
					tableName={tableName}
					previousPage={previousPage}
					canPreviousPage={canPreviousPage}
					nextPage={nextPage}
					canNextPage={canNextPage}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					gotoPage={gotoPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageSizeOptions={[10, 50]}
					totalItems={data.length}
					exportButton={showExportButton && prepareExportData ? (
						<XLSXDownload
							className="mr-3"
							getData={() => prepareExportData(data)}
							fileName={`${t('company.employeeOverview')} ${moment().format('L')}.xlsx`}
							title={t('table.export')}
							icon
						/>
					) : null}
				/>
			</div>
		</>
	);
}

CompanyStudentTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	renderCustomFilters: PropTypes.func,
	showExportButton: PropTypes.bool,
	prepareExportData: PropTypes.func,
	sortByProps: PropTypes.shape({
		id: PropTypes.string.isRequired,
		desc: PropTypes.string.isRequired,
	}),
};

export default CompanyStudentTable;
