/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import useMergeState from '../../../hooks/use-merge-state';
import moment from '../../../moment';
import CheckboxInput from '../../Form/CheckBoxInput';
import ExternalTrainingModalContent from './ExternalTrainingModalContent';
import TrainingModalContent from './TrainingModalContent';

function TrainingModal({
	show, setShow, student, data = null, handleSubmit,
}) {
	const { t } = useTranslation();

	const initialTraining = {
		name: '',
		theory: true,
		start: null,
		external: true,
		city: '',
		ccvCode: '',
		id: null,
	};

	const initialEnrollment = {
		student: {
			id: student.id,
		},
		training: {},
		company: {
			id: student.company.id,
		},
		code95Hours: 0,
		certificateExpirationDate: null,
		excludeFromRiskCalculation: false,
		id: null,
	};

	const [excludeCertificate, setExcludeCertificate] = useState(false);
	const [certificateExpirationDateManuallyChanged, setCertificateExpirationDateManuallyChanged] = useState(false);
	const [customCertificateExpirationDate, setCustomCertificateExpirationDate] = useState(null);
	const [training, setTraining] = useMergeState(initialTraining);
	const [enrollment, setEnrollment] = useMergeState(initialEnrollment);

	useEffect(() => {
		if(data !== null) {
			setTraining({
				...data.training,
				start: moment(data.training.start),
			});
			setExcludeCertificate(data.excludeFromRiskCalculation);
			const certificateExpirationDate = data.certificateExpirationDate ? moment(data.certificateExpirationDate, 'L') : null;
			setEnrollment({
				...data,
				certificateExpirationDate,
			});
			setCertificateExpirationDateManuallyChanged(data.certificateDateManuallyUpdated);
			setCustomCertificateExpirationDate(certificateExpirationDate);
		} else {
			setExcludeCertificate(false);
			setTraining(initialTraining);
			setEnrollment(initialEnrollment);
		}
	}, [data, show]);

	const cancel = () => {
		setShow(false);
		// Set a small timeout to prevent the modal content changing on close
		setTimeout(() => {
			setEnrollment(initialEnrollment);
			setTraining(initialTraining);
		}, 300);
	};

	const getExcludeCertificateCheckbox = () => (
		<div className="mr-auto">
			<CheckboxInput
				type="checkbox"
				label={t('trainings.exclude_certificate')}
				onChange={({ target }) => setExcludeCertificate(target.checked)}
				value={excludeCertificate}
				name="excludeCertificate"
				id="excludeCertificate"
				checked={excludeCertificate}
			/>
		</div>
	);

	return (
		<Modal show={show} onHide={cancel} size="lg">
			{
				training.external && !student.archived ? (
					<ExternalTrainingModalContent
						training={training}
						setTraining={setTraining}
						getExcludeCertificateCheckbox={getExcludeCertificateCheckbox}
						enrollment={enrollment}
						setEnrollment={setEnrollment}
						excludeCertificate={excludeCertificate}
						handleSubmit={handleSubmit}
						setShow={setShow}
						cancel={cancel}
					/>
				) : (
					<TrainingModalContent
						training={training}
						enrollment={enrollment}
						getExcludeCertificateCheckbox={getExcludeCertificateCheckbox}
						cancel={cancel}
						handleSubmit={handleSubmit}
						excludeCertificate={excludeCertificate}
						certificateExpirationDateManuallyChanged={certificateExpirationDateManuallyChanged}
						setCertificateExpirationDateManuallyChanged={setCertificateExpirationDateManuallyChanged}
						customCertificateExpirationDate={customCertificateExpirationDate}
						setCustomCertificateExpirationDate={setCustomCertificateExpirationDate}
						student={student}
					/>
				)
			}
		</Modal>
	);
}

export default TrainingModal;
