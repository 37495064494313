import React, { useEffect, useMemo, useState } from 'react';
import {
	useExpanded, useGlobalFilter, usePagination, useSortBy, useTable,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import useUserPreferences from '../../hooks/use-user-preferences';
import { LOGBOOK_TABLE } from '../../constants/tables';
import TableHeader from './Default/TableHeader';
import DefaultTableBody from './Default/DefaultTableBody';
import TableFooter from './Default/TableFooter';
import useApi from '../../hooks/use-api';
import { getLogbookActions, getLogbookData } from '../../constants/routes';
import { useDationUser } from '../../hooks/dation-user';
import * as ROUTES from '../../constants/routes';

function LogbookTable({ columns }) {
	const tableName = LOGBOOK_TABLE;

	const navigate = useNavigate();
	const { t } = useTranslation();
	const { getCurrentCompanyId } = useDationUser();
	const { getPageIndex, setPageIndex, getPageSize } = useUserPreferences();

	const [maxPage, setMaxPage] = useState(1);
	const [searchValue, setSearchValue] = useState(null);
	const [action, setAction] = useState(undefined);

	const [{ data, isLoading }, fetchLogbook] = useApi.get(
		getLogbookData(),
		{
			company: getCurrentCompanyId(),
			pagination: true,
			itemsPerPage: getPageSize(tableName),
			page: getPageIndex(tableName) + 1,
		},
		{ accept: 'application/ld+json' },
	);
	const { 'hydra:totalItems': totalItems, 'hydra:member': results } = data || {};
	const getData = useMemo(() => results || [], [results]);

	const [{ data: logBookActionData }] = useApi.get(getLogbookActions());

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		page,
		state: { pageIndex, pageSize },
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
	} = useTable({
		columns,
		data: getData,
		initialState: {
			pageIndex: getPageIndex(tableName),
			pageSize: getPageSize(tableName),
		},
		manualPagination: true,
		pageCount: maxPage,
	}, useGlobalFilter, useSortBy, useExpanded, usePagination);

	useEffect(() => {
		setMaxPage(Math.ceil(totalItems / pageSize));
	}, [totalItems, pageSize]);

	const updateLogbookData = () => {
		fetchLogbook({
			company: getCurrentCompanyId(),
			pagination: true,
			itemsPerPage: pageSize,
			page: pageIndex + 1, // Table pages are 0-indexed, where api platform pages are 1-indexed
			search: searchValue,
			...(action ? { action } : {}),
		});
	};

	useEffect(() => {
		setPageIndex(tableName, pageIndex);
		updateLogbookData();
	}, [pageSize, pageIndex]);

	return (
		<div className="col-12 col-xl-10 mx-auto mt-3">
			<div className="d-flex justify-content-start" style={{ width: '100%' }}>
				<div className="mx-3">
					<label>
						{t('table.search')}:
						<input
							className="form-control form-control-sm d-inline-block"
							value={searchValue || ''}
							onChange={e => setSearchValue(e.target.value)}
						/>
					</label>
				</div>
				<div className="mx-3">
					<label>
						{t('logbook.action')}
						<select
							className="form-control form-control-sm d-inline-block"
							value={action}
							onChange={e => {
								setAction(e.target.value);
							}}
						>
							<option value="">Alle</option>
							{logBookActionData && logBookActionData.map(({ action: key, translation }) => (
								<option value={key} key={key}>{translation}</option>
							))}
						</select>
					</label>
				</div>
				<Button variant="primary" className="btn-sm align-self-center ml-auto" onClick={updateLogbookData}>{t('table.search')}</Button>
			</div>

			<div className="table-responsive overflow-hidden">
				{isLoading ? (
					<div className="loading-spinner" />
				) : (
					<table {...getTableProps()} className="table table-large">
						<TableHeader headerGroups={headerGroups} />
						<DefaultTableBody
							getTableBodyProps={getTableBodyProps}
							prepareRow={prepareRow}
							data={page}
							handleClick={({ id }) => navigate(ROUTES.logbookEntryDetailPage(id))}
						/>
					</table>
				)}
				<TableFooter
					pageSizeOptions={[10, 50]}
					canNextPage={canNextPage}
					pageOptions={pageOptions}
					nextPage={nextPage}
					setPageSize={setPageSize}
					previousPage={previousPage}
					gotoPage={gotoPage}
					canPreviousPage={canPreviousPage}
					pageSize={pageSize}
					pageIndex={pageIndex}
					totalItems={totalItems}
					showAll={false}
				/>
			</div>
		</div>
	);
}

export default LogbookTable;
