import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ALL_OPTION_VALUE = '*';
const ALL_OPTION = {
	label: 'Select all',
	value: ALL_OPTION_VALUE,
};

export const formatForOptions = list => list?.map(item => ({
	id: item.id,
	value: item.id,
	label: item.name,
}));

function CustomOption({
	isSelected,
	label,
	...props
}) {
	return (
		<div>
			<components.Option {...props}>
				<div className="d-flex justify-content-between align-items-center">
					<label className="ml-2">{label}</label>
					{isSelected && <span className="glyphicons glyphicons-check" />}
				</div>
			</components.Option>
		</div>
	);
}

function CustomMultiValueContainer({
	children,
	maxItemsShown,
	...props
}) {
	const { t } = useTranslation();
	const { label } = props.data;
	const values = props.selectProps.value;
	const index = values.findIndex(selected => selected.label === label);
	const isAllSelected = values.some(value => value.value === ALL_OPTION_VALUE);
	const inputString = values?.length <= maxItemsShown
		? values.map(value => value.label).join(', ')
		: `( ${isAllSelected ? values.length - 1 : values.length} ) ${t('resource-select')}`;

	return index === 0 ? inputString : '';
}

function ClearIndicator({ innerRef, innerProps }) {
	return <span ref={innerRef} {...innerProps} className="glyphicons glyphicons-times mr-1" />;
}

function MultiSelect({
	value,
	options,
	onChange,
	allOption,
	maxItemsShown,
}) {
	const isAllSelected = lastOption => lastOption.value === ALL_OPTION.value;
	const handleOptionsSelectionAndDeselection = (selected, event) => {
		const allOptions = [ALL_OPTION, ...options];

		if(selected !== null && selected.length > 0) {
			// Select all based on last selected option
			if(isAllSelected(selected[selected.length - 1])) {
				return onChange(allOptions);
			}

			let result = [];
			// Once all options are selected
			if(selected.length === options.length) {
				if(selected.includes(ALL_OPTION)) {
					// uncheck select all box
					result = selected.filter(option => option.value !== ALL_OPTION.value);
				} else if(event.action === 'select-option') {
					// check select all box
					result = allOptions;
				}
				return onChange(result);
			}
		}
		return onChange(selected);
	};
	return (
		<span
			data-toggle="popover"
			data-trigger="focus"
		>
			<Select
				value={value}
				options={allOption ? [ALL_OPTION, ...options] : options}
				onChange={(selected, event) => (allOption ? handleOptionsSelectionAndDeselection(selected, event) : onChange(selected))}
				isMulti
				components={{
					Option: CustomOption,
					MultiValueContainer: props => CustomMultiValueContainer({ maxItemsShown, ...props }),
					ClearIndicator,
				}}
				placeholder={null}
				closeMenuOnSelect={false}
				allowSelectAll
				hideSelectedOptions={false}
				className="basic-multi-select"
				classNamePrefix="select"
				styles={{
					valueContainer: provided => ({
						...provided,
						textOverflow: 'ellipsis',
						maxWidth: '90%',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						display: 'initial',
					}),
				}}
			/>
		</span>
	);
}

MultiSelect.propTypes = {
	value: PropTypes.array,
	options: PropTypes.array,
	onChange: PropTypes.func,
	allOption: PropTypes.bool,
	maxItemsShown: PropTypes.number,
};

MultiSelect.defaultProps = {
	allOption: false,
	maxItemsShown: 2,
};

export default MultiSelect;
