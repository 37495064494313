import React from 'react';

function DropdownItem({ item }) {
	return (
		<div tabIndex={0} role="button" onClick={item.handleClick} className="my-1 cursor-pointer dropdown-menu-item">
			<div className="px-1">
				<div className="d-flex flex-row align-items-center">
					<span className={`glyphicons glyphicons-${item.icon} text-secondary font-size-large mr-1`} />
					{item.text}
				</div>
			</div>
			{!item.last && <div className="dropdown-divider" />}
		</div>
	);
}

export default DropdownItem;
