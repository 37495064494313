import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function RightHeaderButton({ title, icon, onClick }) {
	return (
		<Button
			variant="outline-secondary"
			type="button"
			className="d-flex justify-content-center p-1 px-3 btn btn-outline-secondary align-items-center"
			onClick={onClick}
		>
			<span className={`glyphicons ${icon}`} />
			<p className="d-inline mb-0 pb-0 text-left">{title}</p>
		</Button>
	);
}

RightHeaderButton.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

export default RightHeaderButton;
