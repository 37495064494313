import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePagination, useSortBy, useTable } from 'react-table';
import TableHeader from '../Tables/Default/TableHeader';
import TableFooter from '../Tables/Default/TableFooter';
import * as ROUTES from '../../constants/routes';
import { DASHBOARD_DRIVING_LICENSE_TABLE } from '../../constants/tables';

function DashboardTable({
	cardHeader,
	columns,
	data,
	sortBy,
}) {
	const tableName = DASHBOARD_DRIVING_LICENSE_TABLE;
	const navigate = useNavigate();
	const { t } = useTranslation();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state: { pageIndex, pageSize },
		page,
		previousPage,
		canPreviousPage,
		nextPage,
		canNextPage,
		gotoPage,
		pageOptions,
		setPageSize,
	} = useTable({
		columns,
		data,
		initialState: {
			pageIndex: 0,
			pageSize: 5,
			sortBy,
		},
	}, useSortBy, usePagination);

	return (
		<Card className="m-3 col-5" style={{ minHeight: 300 }}>
			<Card.Body>
				<Card.Title>
					{cardHeader}
				</Card.Title>
				<div className="table-responsive overflow-hidden">
					<table {...getTableProps()} className="table">
						<TableHeader headerGroups={headerGroups} borderBottom />
						{page.length ? (
							<tbody {...getTableBodyProps()} className="table-light">
								{page.map(row => {
									prepareRow(row);
									const { original: { id } } = row;

									return (
										<tr
											className="tableRowWhite cursor-pointer"
											{...row.getRowProps()}
											onClick={() => navigate(ROUTES.employeeDetailPage(id))}
										>
											{row.cells.map(cell => (
												<td
													className="text-primary-color"
													{...cell.getCellProps()}
												>
													{cell.render('Cell')}
												</td>
											))}
										</tr>
									);
								})}
							</tbody>
						) : (
							<tr>
								<td colSpan={headerGroups[0].headers.length}>{t('dashboard.no_work')}</td>
							</tr>
						)}
					</table>
					{(canNextPage || canPreviousPage)
						&& (
							<TableFooter
								tableName={tableName}
								previousPage={previousPage}
								canPreviousPage={canPreviousPage}
								nextPage={nextPage}
								canNextPage={canNextPage}
								pageIndex={pageIndex}
								pageOptions={pageOptions}
								gotoPage={gotoPage}
								pageSize={pageSize}
								setPageSize={setPageSize}
								pageSizeOptions={[5]}
								condensed
								totalItems={data.length}
							/>
						)}
				</div>
			</Card.Body>
		</Card>
	);
}

export default DashboardTable;
