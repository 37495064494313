import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

function MenuItem({
	name, icon, link,
}) {
	return (
		<div className="col-sm-2">
			<LinkContainer id={name} to={link}>
				<div className="btn btn-outline-dark btn-block">
					<span className={`glyphicons glyphicons-${icon}`} style={{ fontSize: '2rem' }} />
					<div>{name}</div>
				</div>
			</LinkContainer>
		</div>
	);
}

MenuItem.propTypes = {
	name: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
};
export default MenuItem;
