import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../hooks/firebase';
import { verifyPassword } from '../utils/password';
import ErrorMessage from '../components/common/messages/ErrorMessage';

function SignUpPage() {
	const { t } = useTranslation();
	const firebase = useFirebase();

	const [errorMessage, setErrorMessage] = useState(null);
	const [password, setPassword] = useState('');
	const [isHidden, setIsHidden] = useState(true);
	const [hasAgreed, setHasAgreed] = useState(false);

	const email = new URLSearchParams(window.location.search).get('emailAddress')?.split(' ').join('+');

	const signUp = new URLSearchParams(window.location.search).get('signUp');

	const isSignUpLink = signUp === '1';

	const isLoginLink = firebase.isValidSignInWithEmailLink(window.location.href) && !!email;

	const getPasswordVerification = () => {
		const errors = verifyPassword(password);
		return errors
			? (
				<small>
					<ul style={{ columns: 2 }}>
						{errors.map(error => <li className="text-primary">{t(error)}</li>)}
					</ul>
				</small>
			) : null;
	};

	const loginAndUpdatePassword = async () => {
		const errors = verifyPassword(password);
		if(errors.length > 0) {
			return;
		}

		// keep the set order like this to make sure that values are not missing from the ContextProvider when the useEffect is triggered in MainPage.js
		firebase.setUserPassword(password);
		firebase.setUserSignUp(true);

		firebase.signInWithLink(email, window.location.href)
			.catch(() => setErrorMessage(t('portal.signup_error_text')));
	};

	return isLoginLink ? (
		<div className="d-flex flex-column align-content-between" style={{ minHeight: 180 }}>
			<ErrorMessage message={errorMessage} />
			<h5 className="mb-4">{t('portal.set_password')}</h5>

			<div className="form-group mb-0">
				<div className="input-group">
					<input
						type={isHidden ? 'password' : 'text'}
						className="form-control"
						value={password}
						onChange={e => setPassword(e.target.value)}
						placeholder={t('portal.set_password_placeholder')}
						autoComplete="current-password"
						style={{
							height: 39,
						}}
					/>
					<div className="input-group-append">
						<button className="btn btn-outline border" type="button" onClick={() => setIsHidden(!isHidden)}>
							{
								isHidden
									? <span className="glyphicons glyphicons-eye-off" />
									: <span className="glyphicons glyphicons-eye" />
							}
						</button>
					</div>
				</div>
			</div>

			{isSignUpLink ? (
				<div className="form-group form-check mt-2">
					<input
						type="checkbox"
						className="form-check-input"
						id="terms-and-conditions"
						onChange={() => setHasAgreed(!hasAgreed)}
					/>

					<label className="form-check-label checkbox-label" htmlFor="terms-and-conditions">
						{`${t('portal.agreed')} `}
						<a
							href="https://www.dation.nl/wp-content/uploads/2020/09/Algemene-Voorwaarden-Dation.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t('portal.terms_and_conditions')}
						</a>
					</label>
				</div>
			) : ''}

			<button
				type="button"
				disabled={isSignUpLink && !hasAgreed}
				className="btn btn-primary btn-block mt-2"
				onClick={() => loginAndUpdatePassword()}
			>
				{t('portal.set_password_button_text')}
			</button>

			<div style={{ height: '32px' }}>
				{password && getPasswordVerification()}
			</div>

		</div>
	) : <ErrorMessage message={t('portal.link_expired')} />;
}

export default SignUpPage;
