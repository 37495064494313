import React, { useCallback, useEffect, useState } from 'react';
import {
	Button, Modal, ModalBody, ModalFooter, ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDationUser } from '../../../hooks/dation-user';
import { getDateFormat, getWeekdayFormat } from '../../../utils/date';
import {
	findExistingReservation,
	getCalculatedReservationAmounts,
	isPlanningRestricted,
} from '../../../utils/training';
import PlanningTooltip from '../../common/PlanningTooltip';
import * as ROUTES from '../../../constants/routes';
import { useToast } from '../../../hooks/use-toast';
import usePostPutModalState from '../../../hooks/post-put-modal';
import ErrorMessage from '../../common/messages/ErrorMessage';
import StudentsSelection from '../../Employees/StudentsSelection';
import Slots from '../PlannedTrainingModal/Slots';
import CompanySelect from './CompanySelect';
import InfoMessage from '../../common/messages/InfoMessage';
import { CODE_95_ENABLED, useViewPreferences } from '../../../hooks/view-preferences';
import useCheckStudentEnrollments from '../../../hooks/use-check-student-enrollments';
import StudentWarnings from '../../Employees/StudentWarnings';

function ReservationModal({
	show, setShow, training, setTraining, handleSubmit,
}) {
	const { t } = useTranslation();
	const { getCurrentCompanyId } = useDationUser();
	const toast = useToast();
	const { isPreferenceOptionEnabled } = useViewPreferences();

	const initialReservation = {
		amountPlanned: 0,
		amountReserved: 1,
		company: {
			id: getCurrentCompanyId(),
		},
		trainingReservationCompanies: [
			{ company: { id: getCurrentCompanyId() } },
		],
	};
	const [reservationOptions, setReservationOptions] = useState([]);
	const [canDecreaseReservationAmount, setCanDecreaseReservationAmount] = useState(false);
	const [mainReservationHolderNameForInfoMessage, setMainReservationHolderNameForInfoMessage] = useState(null);

	const {
		entity,
		isDisabled,
		errorMessage,
		setErrorMessage,
		setEntity,
		handleClose,
		submitEntity,
	} = usePostPutModalState({
		name: 'reservations',
		initialEntity: { id: null, trainingReservation: initialReservation },
		postEntityRoute: ROUTES.trainingReservations,
		putEntityRoute: ROUTES.getTrainingReservation,
	});

	const {
		studentWarnings, setStudentWarnings, selectedStudents, setSelectedStudents, removeWarning,
	} = useCheckStudentEnrollments(training);

	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const [message, setMessage] = useState(null);

	const code95Enabled = isPreferenceOptionEnabled(CODE_95_ENABLED);

	useEffect(() => {
		if(training && show) {
			const existingReservation = findExistingReservation(training.trainingReservations, getCurrentCompanyId()) || {};
			if(existingReservation.company && existingReservation.company.id !== getCurrentCompanyId()) {
				setMainReservationHolderNameForInfoMessage(existingReservation.company.name);
				return;
			}

			const selectedSharedReservations = existingReservation.id
				? existingReservation
					.trainingReservationCompanies
					.filter(trainingReservationCompany => trainingReservationCompany.company.id !== getCurrentCompanyId())
					.map(trainingReservationCompany => ({
						value: trainingReservationCompany.company.id,
						label: trainingReservationCompany.company.name,
					}))
				: [];
			setSelectedCompanies(selectedSharedReservations);
			setEntity({
				id: existingReservation?.id,
				trainingReservation: {
					...initialReservation,
					...existingReservation,
					training: {
						id: training.id,
					},
				},
			});
			setCanDecreaseReservationAmount(!isPlanningRestricted(training));
		}
	}, [training, show, getCurrentCompanyId]);

	useEffect(() => {
		if(show && training) {
			const existingReservation = findExistingReservation(training.trainingReservations, getCurrentCompanyId()) || {};
			const options = [];
			let start = Math.max(existingReservation?.amountPlanned ?? 1, selectedStudents.length);

			if(!canDecreaseReservationAmount) {
				start = Math.max(start, existingReservation?.amountReserved || 0);
			}
			// options are shown if the amount is higher than what is currently planned.
			for(
				let option = start;
				option <= training.freeCapacity + (existingReservation?.amountReserved || 0);
				// eslint-disable-next-line no-plusplus
				option++
			) {
				options.push(option);
			}
			setReservationOptions(options);
		}
	}, [show, selectedStudents, canDecreaseReservationAmount]);

	const updateTrainingReservations = useCallback(newTrainingReservationCompanies => {
		if(entity.trainingReservation.trainingReservationCompanies) {
			const originalTrainingReservationCompanies = entity
				.trainingReservation
				.trainingReservationCompanies
				.filter(trainingReservationCompany => trainingReservationCompany.company.id === entity.trainingReservation.company.id);

			setEntity({
				...entity,
				trainingReservation: {
					...entity.trainingReservation,
					trainingReservationCompanies: [
						...originalTrainingReservationCompanies,
						...newTrainingReservationCompanies,
					],
				},
			});
		}
	}, [entity]);

	useEffect(() => {
		updateTrainingReservations(selectedCompanies.map(({ value }) => ({ company: { id: value } })));
	}, [selectedCompanies]);

	const cancel = () => {
		setShow(false);
		setTraining(null);
		setEntity({ id: null, trainingReservation: initialReservation });
		setReservationOptions([]);
		setCanDecreaseReservationAmount(true);
		setSelectedStudents([]);
		setStudentWarnings([]);
		setSelectedCompanies([]);
		setErrorMessage(null);
		setMessage('');
		setMainReservationHolderNameForInfoMessage(false);
	};

	const submitEnrollment = () => {
		const enrollmentDetails = selectedStudents.map(({ value, customFields }) => ({
			student: { id: value },
			company: { id: getCurrentCompanyId() },
			code95: customFields.ccv,
			soob: customFields.soob,
		}));
		const extraBodyData = {
			enrollmentDetails,
			message,
		};
		submitEntity(extraBodyData).then(successMessage => {
			handleClose();
			handleSubmit();
			toast.addToast(successMessage);
			cancel();
		}).catch();
	};
	const { trainingReservation = null } = entity || {};
	const { slots } = training || [];
	const {
		amountPlanned,
		amountReserved,
	} = trainingReservation ? getCalculatedReservationAmounts(trainingReservation, getCurrentCompanyId()) : {};

	const getNotMainReservationHolderMessage = () => {
		const defaultMessage = t('reservations.notMainReservationHolderError', { companyName: mainReservationHolderNameForInfoMessage });
		return !training.composite
			? defaultMessage
			: <><p>{defaultMessage}</p><p>{t('reservations.complexNotMainReservationHolderError')}</p></>;
	};

	const getExtraInfoMessage = () => {
		let infoMessage = t('trainings.composite_training_info');
		if(code95Enabled) {
			infoMessage += t('trainings.composite_training_info_example');
		}

		return infoMessage;
	};

	return training?.id ? (
		<Modal
			show={show}
			onHide={cancel}
			size="lg"
		>
			<Modal.Header className="d-flex align-items-center" closeButton>
				<ModalTitle>{t('trainings.reserve')}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<>
					<ErrorMessage message={errorMessage} />
					<dl className="row">
						<dt className="col-3 text-primary-color">{t('trainings.date')}</dt>
						<dd className="col-8" style={{ minHeight: 24 }}>
							<div className="row">
								<div className="col-1">{getWeekdayFormat(training.start)}</div>
								<div className="col-5 pl-0 d-flex flex-row">
									<div>{getDateFormat(training.start)}</div>
								</div>
							</div>
						</dd>

						{slots && slots.length > 0
							&& <Slots titleClassnames="col-3" descriptionClassnames="col-8" slots={slots} />}

						<dt className="col-3 text-primary-color">{t('trainings.location')}</dt>
						<dd className="col-9" style={{ minHeight: 24 }}>{training.city}</dd>

						<dt className="col-3 text-primary-color">{t('trainings.course')}</dt>
						<dd className="col-9" style={{ minHeight: 24 }}>{training.name}</dd>

						{amountReserved > 0 && (
							<>
								<dt className="col-3 text-primary-color">{t('trainings.reservation')}</dt>
								<dd className="col-9">{t('trainings.reservation_amounts', {
									amountPlanned,
									amountReserved,
								})}
								</dd>
							</>
						)}
					</dl>
					{mainReservationHolderNameForInfoMessage ? (
						<InfoMessage message={getNotMainReservationHolderMessage()} />
					) : (
						<>
							<div className="form-group row">
								<label
									className="col-form-label col-5"
								>{t('reservations.reservationAmountLabel')}
								</label>
								<select
									className="form-control col-1"
									value={trainingReservation?.amountReserved || 0}
									onChange={e => setEntity({
										...entity,
										trainingReservation: {
											...trainingReservation,
											amountReserved: parseInt(e.target.value, 10),
										},
									})}
								>
									{reservationOptions.map(amount => (
										<option
											key={amount}
											value={amount}
										>
											{amount}
										</option>
									))}
								</select>
								<div className="col-1">
									{!canDecreaseReservationAmount && <PlanningTooltip isReservation />}
								</div>
							</div>
							<CompanySelect selected={selectedCompanies} setSelected={setSelectedCompanies} />
						</>
					)}
					{training?.composite ? (
						<>
							<StudentWarnings warnings={studentWarnings} onRemove={removeWarning} />
							<StudentsSelection
								show={show}
								training={training}
								optional
								selected={selectedStudents}
								setSelected={setSelectedStudents}
								maxSelected={trainingReservation?.amountReserved ?? 1}
								message={message}
								setMessage={setMessage}
								disabled={!!errorMessage}
							/>
							<InfoMessage
								message={selectedStudents?.length > 0
									? getExtraInfoMessage()
									: null}
							/>
						</>
					) : null}
				</>
			</ModalBody>
			<ModalFooter>
				{errorMessage || (mainReservationHolderNameForInfoMessage && !training.composite) ? (
					<Button
						variant="outline-secondary"
						onClick={cancel}
					>
						{t('modalActions.close')}
					</Button>
				) : (
					<>
						<Button
							variant="outline-secondary"
							onClick={cancel}
							disabled={isDisabled}
						>
							{t('modalActions.cancel')}
						</Button>
						<Button
							variant="primary"
							onClick={submitEnrollment}
							disabled={isDisabled}
						>
							{t('trainings.reserve')}
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	) : null;
}

ReservationModal.propTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	training: PropTypes.object,
	setTraining: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default ReservationModal;
