import React, { useEffect } from 'react';
import {
	useGlobalFilter, usePagination, useSortBy, useTable,
} from 'react-table';
import PropTypes from 'prop-types';
import TableHeader from './Default/TableHeader';
import DefaultTableBody from './Default/DefaultTableBody';
import TableFooter from './Default/TableFooter';
import { ESTABLISHMENT_TABLE } from '../../constants/tables';
import useUserPreferences from '../../hooks/use-user-preferences';
import TableGlobalFilter from './Default/TableGlobalFilter';

const EstablishmentsTable = React.memo(({
	columns,
	data,
	className = '',
	handleClick = null,

}) => {
	const { getPageIndex, setPageIndex, getPageSize } = useUserPreferences();
	const tableName = ESTABLISHMENT_TABLE;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		page,
		state: { pageIndex, pageSize, globalFilter },
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
		setGlobalFilter,
	} = useTable({
		columns,
		data,
		initialState: {
			pageIndex: getPageIndex(tableName),
			pageSize: getPageSize(tableName),
			sortBy: [
				{
					id: 'name',
					desc: false,
				},
			],
		},
	}, useGlobalFilter, useSortBy, usePagination);

	useEffect(() => {
		setPageIndex(tableName, pageIndex);
	}, [pageIndex]);

	return (
		<div className={className}>
			<div className="d-flex justify-content-between align-items-end">
				<TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
			</div>
			<div className="table-responsive overflow-hidden">
				<table {...getTableProps()} className="table table-large">
					<TableHeader headerGroups={headerGroups} />
					<DefaultTableBody
						getTableBodyProps={getTableBodyProps}
						data={page}
						prepareRow={prepareRow}
						handleClick={handleClick}
					/>
				</table>
				<TableFooter
					tableName={tableName}
					previousPage={previousPage}
					canPreviousPage={canPreviousPage}
					nextPage={nextPage}
					canNextPage={canNextPage}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
					gotoPage={gotoPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageSizeOptions={[10, 50]}
					totalItems={data.length}
				/>
			</div>
		</div>
	);
});

EstablishmentsTable.proptypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	className: PropTypes.string,
	handleClick: PropTypes.func,
};

export default EstablishmentsTable;
