import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useUserPreferences from '../../../hooks/use-user-preferences';
import TableFooterNavigation from './TableFooterNavigation';

function TableFooter({
	tableName = null,
	previousPage,
	canPreviousPage,
	nextPage,
	canNextPage,
	pageIndex,
	pageOptions,
	gotoPage,
	pageSize,
	setPageSize,
	pageSizeOptions,
	totalItems,
	exportButton,
	condensed = false,
	showAll = true,
}) {
	const { t } = useTranslation();
	const { setPageSize: setPageSizePreference } = useUserPreferences();

	const handlePageSizeChange = e => {
		// only setPageSizePreference if prop TableName is provided
		tableName && setPageSizePreference(tableName, e.target.value);
		setPageSize(Number(e.target.value));
	};

	return (
		<div className="d-flex justify-content-between m-auto">
			<div className="d-flex flex-row">
				{exportButton}
				{pageSizeOptions.length > 1 && (
					<div className="form-inline ml-1">
						<select
							className="form-control mr-2"
							value={pageSize}
							onChange={handlePageSizeChange}
						>
							{showAll && <option value={totalItems}>{t('table.all')}</option>}
							{pageSizeOptions.map(size => (
								<option key={size} value={size}>
									{`${t('table.show')} ${size}`}
								</option>
							))}
						</select>
					</div>
				)}
			</div>
			<div className="text-center">
				<span className="align-middle">
					{t('table.page')}
					<em className="ml-1">
						{`${pageIndex + 1} ${t('table.of')} ${pageOptions.length || 1}`}
					</em>
				</span>
			</div>

			<TableFooterNavigation
				canNextPage={canNextPage}
				canPreviousPage={canPreviousPage}
				gotoPage={gotoPage}
				nextPage={nextPage}
				pageIndex={pageIndex}
				pageOptions={pageOptions}
				previousPage={previousPage}
				condensed={condensed}
			/>
		</div>
	);
}

TableFooter.propTypes = {
	tableName: PropTypes.string,
	previousPage: PropTypes.func.isRequired,
	canPreviousPage: PropTypes.bool.isRequired,
	nextPage: PropTypes.func.isRequired,
	canNextPage: PropTypes.bool.isRequired,
	pageIndex: PropTypes.number.isRequired,
	pageOptions: PropTypes.array.isRequired,
	gotoPage: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	setPageSize: PropTypes.func.isRequired,
	pageSizeOptions: PropTypes.array.isRequired,
	totalItems: PropTypes.number.isRequired,
	exportButton: PropTypes.element,
	condensed: PropTypes.bool,
	showAll: PropTypes.bool,
};

export default TableFooter;
