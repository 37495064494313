import React, { useEffect, useMemo } from 'react';
import {
	Button, Col, Form, FormControl, FormLabel, Modal, ModalBody, ModalFooter, ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../hooks/use-toast';
import * as ROUTES from '../../constants/routes';
import usePostPutModalState from '../../hooks/post-put-modal';
import File from '../Form/File';
import { useApi } from '../../hooks/use-api';
import { getFileExtension, getFileNameExtensionMatch } from '../../constants/file';
import ErrorMessage from '../common/messages/ErrorMessage';
import { useDationUser } from '../../hooks/dation-user';

export const DocumentEntity = {
	name: 'documents',
	initialEntity: {
		id: null,
		fileName: null,
		fileData: null,
		creationDate: null,
		storageBucketIdentifier: null,
	},
	postEntityRoute: ROUTES.postDocument,
	putEntityRoute: ROUTES.putDocument,
};

const DocumentModal = React.memo(({
	show, setShow, handleSubmit, selectedEntity, studentId, handleDelete,
}) => {
	const { t } = useTranslation();
	const toast = useToast();
	const { isDationInstanceUser } = useDationUser();

	const initialState = useMemo(
		() => ({ ...DocumentEntity }),
		[studentId],
	);

	const {
		entity = DocumentEntity.initialEntity,
		isDisabled,
		setIsDisabled,
		isValidated,
		errorMessage,
		isLoading,
		setErrorMessage,
		setEntity,
		handleClose,
		submitEntity,
	} = usePostPutModalState(initialState);

	const [
		{ data: downloadedDocument, isLoading: isDownloadingDocument },
		downloadDocument,
	] = useApi.get(
		ROUTES.downloadDocument(entity?.id),
		{},
		{ cachePolicy: 'no-cache', lazy: true, requestConfig: { responseType: 'blob' } },
	);

	useEffect(() => {
		setEntity(selectedEntity !== null ? selectedEntity : DocumentEntity.initialEntity);
	}, [selectedEntity, show]);

	const close = () => {
		setShow(false);
		handleClose();
	};

	const onChange = fileChangeResponse => {
		fileChangeResponse
			.then(() => setIsDisabled(false))
			.catch(({ error }) => {
				setErrorMessage(error);
				setIsDisabled(true);
			});
	};

	const handleFileChange = async ({ file, base64File }) => {
		setErrorMessage(null);
		if(file) {
			const fileNameExtension = getFileNameExtensionMatch(file?.name);
			setEntity({
				fileName: file?.name.slice(0, -fileNameExtension.length) ?? null,
				fileData: base64File,
			});
		}
	};

	const onSubmit = async event => {
		event.stopPropagation();
		event.preventDefault();

		submitEntity({
			student: { id: studentId },
		}).then(successMessage => {
			close();
			handleSubmit();
			toast.addToast(successMessage);
		}).catch();
	};

	const handleDownload = () => {
		downloadDocument({}, ROUTES.downloadDocument(entity?.id));
	};

	useEffect(() => {
		if(downloadedDocument) {
			const downloadUrl = URL.createObjectURL(downloadedDocument);

			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = `${entity.fileName}${getFileExtension(downloadedDocument.type)}`;
			document.body.appendChild(a);
			a.click();
		}
	}, [downloadedDocument]);

	return (
		<Modal show={show} onHide={close} size="lg">
			<Form noValidate validated={isValidated} onSubmit={onSubmit}>
				<Modal.Header closeButton={!isLoading}>
					<ModalTitle>{entity.id ? t('documents.edit') : t('documents.add')}</ModalTitle>
				</Modal.Header>
				<ModalBody>
					<ErrorMessage message={errorMessage} />
					<Form.Row className="mb-12">
						<Col xs={12} md={12}>
							{!entity.id && (
								<File
									showFileName={false}
									onChange={onChange}
									handleFileChange={handleFileChange}
								/>
							)}
							<FormLabel>{t('documents.name')}</FormLabel>
							<FormControl
								id="entity-name"
								type="text"
								className="mb-3"
								disabled={entity.fileName === null || isDationInstanceUser()}
								value={entity.fileName ?? ''}
								onChange={({ target }) => setEntity({ fileName: target.value })}
							/>
							{entity.id && (
								<div className="py-1">
									<button
										type="button"
										onClick={handleDownload}
										className="btn btn-outline-secondary d-flex align-items-center"
									>
										<span className="
											glyphicons glyphicons-cloud-download mr-1
											text-center d-inline-block p-0 clickable
											"
										/>
										{t('documents.download')}
									</button>
								</div>
							)}
						</Col>
					</Form.Row>
				</ModalBody>
				<ModalFooter>
					{!isDationInstanceUser() && entity?.id && (
						<Button
							variant="outline-secondary"
							type="button"
							onClick={e => handleDelete(e)}
						>
							{t('modalActions.delete')}
						</Button>
					)}

					<Button
						variant="outline-secondary"
						disabled={isDisabled || isDownloadingDocument}
						onClick={close}
					>
						{isDationInstanceUser() ? t('modalActions.close') : t('modalActions.cancel')}
					</Button>
					{!isDationInstanceUser() && (
						<Button
							variant="primary"
							disabled={isDisabled || isDownloadingDocument}
							type="submit"
						>{entity?.id ? t('modalActions.edit') : t('modalActions.add')}
						</Button>
					)}
					{(isLoading || isDownloadingDocument) && <div className="loading-spinner" />}
				</ModalFooter>
			</Form>
		</Modal>
	);
});

export default DocumentModal;
