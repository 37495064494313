import React, {
	useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button, Card, Col, Form, FormControl,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from '../../moment';
import InfoBar from '../../components/Navigation/InfoBar';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import Address from '../../components/Form/Address';
import {
	dationInstanceEndpoint,
	deleteCompanyContactPerson, EDIT_USER,
	getSendInviteUrl,
	inviteContactPersonToPortal,
} from '../../constants/routes';
import * as ROUTES from '../../constants/routes';
import useMergeState from '../../hooks/use-merge-state';
import FormCol from '../../components/Form/FormCol';
import { useDationUser } from '../../hooks/dation-user';
import UsersList from '../UsersList';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { parseErrorMessage } from '../../utils/parseErrors';
import SmallFormLabel from '../../components/Form/SmallFormLabel';
import ErrorMessage from '../../components/common/messages/ErrorMessage';
import MultiSelect, { formatForOptions } from '../../components/common/MultiSelect';
import DropdownButton from '../../components/common/DropdownButton';

function CompanyDetailPage() {
	const { companyId } = useParams();
	const { t } = useTranslation();
	const toast = useToast();
	const exportTitle = useRef('');
	const user = useDationUser();
	const navigate = useNavigate();

	const initialState = {
		name: undefined,
		soobNumber: undefined,
		emailAddress: undefined,
		vatNumber: undefined,
		phone: undefined,
		website: undefined,
		companyRegistrationNumber: undefined,
		department: undefined,
		archived: false,
		sharedCompanies: [],
		lastExportDate: undefined,
		visitingAddress: {
			streetName: undefined,
			postalCode: undefined,
			houseNumber: undefined,
			country: undefined,
			city: undefined,
			addition: undefined,
		},
		billingAddress: {
			streetName: undefined,
			postalCode: undefined,
			houseNumber: undefined,
			country: undefined,
			city: undefined,
			addition: undefined,
		},
	};

	const [state, mergeState] = useMergeState(initialState);
	const [isValidated, setValidated] = useState(false);
	const [isDisabled, setDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [canEditCompany, setCanEditCompany] = useState(false);
	const [showConfirmArchive, confirmArchive] = useState(false);
	const [isArchived, setArchived] = useState(false);
	const [dationInstances, setDationInstances] = useState([]);

	const [{ data }] = useApi.get(ROUTES.companyPage(companyId), {}, { cachePolicy: 'no-cache' });
	const [{ data: publicCompanies = [] }] = useApi.get(ROUTES.COMPANIES, { archived: false }, { cachePolicy: 'no-cache' });
	const [{ data: updatedCompanyData }, submitCompany] = useApi.put(ROUTES.companyPage(companyId));
	const [{ data: exportedStudents }, exportEmployees] = useApi.get(ROUTES.exportCompanyEmployees(companyId), {}, { lazy: true });
	const [{ data: contactPersons }, fetchContactPersons] = useApi.get(ROUTES.getCompanyContactPersons(companyId), {}, { cachePolicy: 'no-cache' });
	const [{ data: dationInstanceData }] = useApi.get(`${dationInstanceEndpoint()}`, { archived: false }, { cachePolicy: 'no-cache' });
	const [shouldDownloadExport, setShouldDownloadExport] = useState(false);

	useEffect(() => {
		setDationInstances(dationInstanceData);
	}, [dationInstanceData]);

	useEffect(() => {
		if(updatedCompanyData && updatedCompanyData.archived !== 'undefined') {
			setArchived(updatedCompanyData.archived);
		}
	}, [updatedCompanyData]);

	useEffect(() => {
		if(shouldDownloadExport && exportedStudents !== null) {
			const download = document.createElement('a');
			download.href = `data:application/xlsx;base64,${exportedStudents.content}`;
			download.download = `${exportTitle.current}.xlsx`;
			download.click();
			setShouldDownloadExport(false);
		}
	}, [exportedStudents]);

	const handleExport = params => {
		exportTitle.current = params?.exportAll
			? t('company.export_all_name', { currentDate: moment().format('L'), companyName: state.name })
			: t('company.partial_export', { currentDate: moment().format('L'), companyName: state.name, lastExportDate: moment(state.lastExportDate).format('L') });
		setShouldDownloadExport(true);
		exportEmployees(params);
	};

	const submit = event => {
		event.preventDefault();
		event.stopPropagation();
		setErrorMessage(null);
		setDisabled(true);

		const form = event.currentTarget;

		if(form.checkValidity() !== false) {
			submitCompany(state)
				.then(() => {
					toast.addToast(t('company.updated'));
				})
				.catch(e => {
					setErrorMessage(
						Array.isArray(e)
							? e.join('. ')
							: e,
					);
				})
				.finally(() => {
					setDisabled(false);
				});
		} else {
			setDisabled(false);
		}

		setValidated(true);
	};

	const selectOptions = useMemo(() => {
		if(publicCompanies?.length > 0) {
			// Exclude own company from list
			return formatForOptions(publicCompanies.filter(company => company.id !== parseInt(companyId, 10)));
		}
		return [];
	}, [publicCompanies]);

	useEffect(() => {
		if(data) {
			setArchived(data.archived);
			const editable = data.dationIdentifiers
				.some(identifier => identifier.dationInstance.id === user.user.dationInstance.id);
			setCanEditCompany(editable);
			mergeState({ ...data, sharedCompanies: formatForOptions(data.sharedCompanies) });
		}
	}, [data, mergeState, user]);

	const handleChange = event => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		mergeState(
			{ [event.target.name]: value },
		);
	};

	const handlePrimaryEducatorChange = event => {
		mergeState({
			primaryDationInstance: {
				id: event.target.value,
			},
		});
	};

	const handleVisitingAddressChange = event => {
		mergeState({
			visitingAddress: {
				...state.visitingAddress,
				[event.target.name]: event.target.value,
			},
		});
	};

	const handleBillingAddressChange = event => {
		mergeState({
			billingAddress: {
				...state.billingAddress,
				[event.target.name]: event.target.value,
			},
		});
	};

	const toggleArchive = () => {
		const companyData = {
			companyRegistrationNumber: state.companyRegistrationNumber,
			archived: !isArchived,
		};
		submitCompany(companyData).then(() => {
			fetchContactPersons();
			confirmArchive(false);
			toast.addToast(isArchived ? t('company.company_activated') : t('company.company_archived'));
		}).catch(e => {
			confirmArchive(false);
			const error = `${isArchived ? t('company.activate_company_error') : t('company.archive_company_error')} ${parseErrorMessage(e)}`;
			toast.addToast(error, 'error');
		});
	};

	const dropdownOptions = useMemo(() => [
		{
			id: 1,
			text: t('company.export_all_employees'),
			icon: 'export',
			handleClick: () => handleExport({ exportAll: true }),
		},
		{
			id: 2,
			text: t('company.export_employees_from_last_export'),
			icon: 'export',
			handleClick: () => handleExport(),
		},
		canEditCompany ? {
			id: 3,
			text: isArchived ? t('company.activate') : t('company.archive'),
			icon: isArchived ? 'undo' : 'archive',
			handleClick: () => confirmArchive(true),
		} : null,
	].filter(item => item), [isArchived, handleExport, canEditCompany]);

	return (
		<>
			<InfoBar
				backTitle={t('company.companies')}
				badgeTitle={t('company.archived')}
				showBadge={isArchived}
			/>
			<div className="row justify-content-center">
				<div className="col-12 col-xl-11">
					<Card className="mb-3">
						<Card.Body>
							<Form className="row company-form" noValidate validated={isValidated} onSubmit={submit}>
								<div className="col-6 col-xl-6">
									<div className="d-flex justify-content-between">
										<h5>{t('company.general_data')}</h5>
										{canEditCompany && (
											<Button variant="primary" size="sm" disabled={isDisabled} type="submit" className="ml-1">{t('company.update')}</Button>
										)}
									</div>
									<ErrorMessage message={errorMessage} />
									<Form.Row className="mb-3">
										<FormCol label={t('company.name')} name="name" value={state.name} onChange={handleChange} />
										<FormCol label={t('company.soob')} name="soobNumber" value={state.soobNumber} onChange={handleChange} />
									</Form.Row>
									<Form.Row className="mb-3">
										<FormCol
											label={t('company.email')}
											type="email"
											name="emailAddress"
											value={state.emailAddress}
											onChange={handleChange}
											feedback={t('portal.email_not_valid')}
										/>
										<FormCol label={t('company.vatNumber')} name="vatNumber" value={state.vatNumber} onChange={handleChange} />
									</Form.Row>
									<Form.Row className="mb-3">
										<FormCol label={t('company.phone')} name="phoneNumber" value={state.phoneNumber} onChange={handleChange} />
										<FormCol label={t('company.website')} name="website" value={state.website} onChange={handleChange} />
									</Form.Row>
									<Form.Row className="mb-3">
										<FormCol
											id="companyRegistrationNumber"
											label={t('company.company_registration_number')}
											name="companyRegistrationNumber"
											value={state.companyRegistrationNumber}
											onChange={handleChange}
											maxLength="8"
											pattern="\d*"
											minLength="8"
											type="text"
											feedback={t('company.company_registration_number_length')}
										/>
										<FormCol md={6} label={t('company.department')} name="department" value={state.department} onChange={handleChange} />
									</Form.Row>
									<Form.Row className="mb-3">
										<Col xs={6}>
											<SmallFormLabel label={t('company.head_trainer')} />
											<Form.Control
												as="select"
												value={state?.primaryDationInstance?.id}
												onChange={handlePrimaryEducatorChange}
												feedback="Dit veld is verplicht"
												required
												custom
											>
												<option value="">-</option>
												{dationInstances?.map(({ id, name }) => (
													<option value={id} key={id}>{name}</option>
												))}
											</Form.Control>
											<FormControl.Feedback type="invalid">
												{t('add_employee.field_required')}
											</FormControl.Feedback>
										</Col>
										<Col xs={6}>
											<SmallFormLabel label={t('company.share_reservations')} />
											<MultiSelect
												value={state.sharedCompanies}
												options={selectOptions}
												onChange={value => mergeState({ sharedCompanies: value })}
											/>
										</Col>
									</Form.Row>
									<Form.Row className="mb-3">
										<Col xs={6}>
											<Form.Check type="checkbox">
												<Form.Check.Input type="checkbox" name="private" checked={state.private || false} onChange={handleChange} />
												<Form.Check.Label onClick={() => mergeState({
													private: !state.private,
												})}
												>{t('company.private')}
												</Form.Check.Label>
											</Form.Check>
										</Col>
									</Form.Row>
								</div>
								<div className="col-6 col-xl-6">
									<Address
										defaultAddress={state.visitingAddress || {}}
										header={t('company.visiting_address')}
										onChange={handleVisitingAddressChange}
									>
										<DropdownButton
											options={dropdownOptions}
											minWidth="20vw"
											placement="left"
										/>
									</Address>
									<div className="dropdown-divider" />
									<Address
										defaultAddress={state.billingAddress || {}}
										header={t('company.billing_address')}
										onChange={handleBillingAddressChange}
									/>
								</div>
							</Form>
							<ConfirmModal
								show={showConfirmArchive}
								setShow={confirmArchive}
								warning={!isArchived ? t('company.archive_company_warning') : null}
								title={isArchived ? t('company.activate') : t('company.archive')}
								message={isArchived ? t('company.activate_company_confirm') : t('company.archive_company_confirm')}
								handleSubmit={toggleArchive}
							/>
						</Card.Body>
					</Card>
				</div>
				<div className="col-12 col-xl-11 mx-auto py-4">
					<Card>
						<Card.Body>
							<div className="justify-content-between d-flex">
								<h5>{t('add_employee.contact_persons')}</h5>
								<button
									id="add-company-contact-person"
									type="button"
									className="btn btn-outline pt-0"
									onClick={() => (isArchived ? toast.addToast(t('users.add_not_allowed'), 'error') : navigate(EDIT_USER, { state: { userType: 'contact_person', original: {}, company: { id: data?.id } } }))}
								>
									<span className="glyphicons glyphicons-user-plus font-size-large text-secondary" />
								</button>
							</div>
							<UsersList
								listType="contact_person"
								users={contactPersons || []}
								handleSubmit={fetchContactPersons}
								inviteEndpoint={inviteContactPersonToPortal}
								newInviteEndpoint={getSendInviteUrl}
								deleteEndpoint={deleteCompanyContactPerson}
								company={data}
							/>
						</Card.Body>
					</Card>
				</div>
			</div>
		</>
	);
}

export default CompanyDetailPage;
