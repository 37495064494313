/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button, Card, Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfoBar from '../../components/Navigation/InfoBar';
import useApi from '../../hooks/use-api';
import { useToast } from '../../hooks/use-toast';
import {
	dationInstanceUserList,
	EDIT_USER,
	getDationInstance,
	inviteDationInstanceUserToPortal,
	updateDationInstance,
	updateDationInstanceUser,
} from '../../constants/routes';
import useMergeState from '../../hooks/use-merge-state';
import FormCol from '../../components/Form/FormCol';
import { parseErrorMessage } from '../../utils/parseErrors';
import UsersList from '../UsersList';
import ErrorMessage from '../../components/common/messages/ErrorMessage';

function DationInstanceDetailPage() {
	const { dationInstanceId } = useParams();
	const { t } = useTranslation();
	const toast = useToast();
	const navigate = useNavigate();

	const initialState = {
		name: undefined,
		apiKey: undefined,
		emailAddress: undefined,
		administrationId: undefined,
		regionName: undefined,
	};

	const [state, mergeState] = useMergeState(initialState);
	const [isValidated, setValidated] = useState(false);
	const [isDisabled, setDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	// Getting extended fields for dationInstanceEndpoint()
	const [{ data }] = useApi.get(`${getDationInstance(dationInstanceId)}`, { expand: ['portal_admin'] }, { cachePolicy: 'no-cache' });
	const [{ data: dationInstanceUsers }, fetchDationInstanceUsers] = useApi.get(dationInstanceUserList(dationInstanceId));
	const [, submitDationInstance] = useApi.patch(updateDationInstance(dationInstanceId));

	const submit = event => {
		event.preventDefault();
		event.stopPropagation();

		setDisabled(true);

		const form = event.currentTarget;

		if(form.checkValidity() !== false) {
			submitDationInstance(state)
				.then(() => {
					toast.addToast(t('dation_instance.updated'));
				})
				.catch(e => {
					setErrorMessage(parseErrorMessage(e));
				})
				.finally(() => setDisabled(false));
		} else {
			setValidated(true);
			setDisabled(false);
		}
	};

	useEffect(() => {
		if(data) {
			mergeState(data);
		}
	}, [data, mergeState]);

	const handleChange = event => {
		const { target } = event;
		let value = target.type === 'checkbox' ? target.checked : target.value;

		value = target.type === 'number' ? Number(value) : target.value;

		mergeState(
			{ [event.target.name]: value },
		);
	};
	return (
		<>
			<InfoBar
				backTitle={t('navigation.adminUser.dation_instances')}
				badgeTitle={t('company.archived')}
				showBadge={state.archived}
			/>
			<div className="row justify-content-center">
				<div className="col-12 col-xl-5">
					<Card className="mb-3">
						<Card.Body>
							<Form
								className="company-form"
								noValidate
								validated={isValidated}
								onSubmit={submit}
							>
								<div className="d-flex justify-content-between">
									<h5>{t('company.general_data')}</h5>
									<Button
										variant="primary"
										size="sm"
										disabled={isDisabled}
										type="submit"
										className="ml-1"
									>{t('dation_instance.update')}
									</Button>
								</div>
								<ErrorMessage message={errorMessage} />
								<Form.Row className="mb-3">
									<FormCol
										label={t('dation_instance.name')}
										name="name"
										value={state.name}
										onChange={handleChange}
									/>
									<FormCol
										type="text"
										label={t('dation_instance.api_id')}
										name="apiId"
										value={state.apiId}
										onChange={handleChange}
										feedback={t('add_employee.field_required')}
										required
									/>
								</Form.Row>
								<Form.Row className="mb-3">
									<FormCol
										label={t('dation_instance.email_address')}
										type="email"
										name="emailAddress"
										value={state.emailAddress}
										onChange={handleChange}
										feedback={t('portal.email_not_valid')}
										required
									/>
									<FormCol
										type="number"
										label={t('dation_instance.administration_id')}
										name="administrationId"
										value={state.administrationId}
										onChange={handleChange}
										feedback={t('add_employee.field_required')}
										required
									/>
								</Form.Row>
								<Form.Row className="mb-3">
									<FormCol
										label={t('dation_instance.region_name')}
										type="text"
										name="regionName"
										value={state.regionName}
										onChange={handleChange}
										feedback={t('add_employee.field_required')}
										required
									/>
									<FormCol
										label={t('dation_instance.dation_id')}
										type="number"
										name="dationId"
										value={state.dationId}
										onChange={handleChange}
										feedback={t('add_employee.field_required')}
										required
									/>
								</Form.Row>

								<Form.Row>
									<FormCol
										label={t('dation_instance.handle')}
										type="text"
										name="handle"
										value={state.handle}
										onChange={handleChange}
										feedback={t('add_employee.field_required')}
										required
									/>
									<FormCol
										label={t('dation_instance.phone_number')}
										type="text"
										name="phoneNumber"
										value={state.phoneNumber}
										onChange={handleChange}
									/>
								</Form.Row>
							</Form>
						</Card.Body>
					</Card>
				</div>
				<div className="col-12 col-xl-7">
					<Card>
						<Card.Body>
							<div className="justify-content-between d-flex">
								<h5>{t('dation_instance_user.add')}</h5>
								<button
									id="add-dation-instance-user"
									type="button"
									className="btn btn-outline pt-0"
									onClick={() => navigate(
										EDIT_USER,
										{
											state: {
												userType: 'dation_instance_user',
												original: {},
												dationInstance: { id: data?.id },
											},
										},
									)}
								>
									<span className="glyphicons glyphicons-user-plus font-size-large text-secondary" />
								</button>
							</div>
							<UsersList
								listType="dation_instance_user"
								users={dationInstanceUsers || []}
								handleSubmit={fetchDationInstanceUsers}
								inviteEndpoint={inviteDationInstanceUserToPortal}
								deleteEndpoint={updateDationInstanceUser}
							/>
						</Card.Body>
					</Card>
				</div>
			</div>
		</>
	);
}

export default DationInstanceDetailPage;
