/* eslint-disable no-unused-vars */
import React from 'react';
import { useDationUser } from '../hooks/dation-user';
import useApi from '../hooks/use-api';
import { getCompanyStudents } from '../constants/routes';
import InfoBar from '../components/Navigation/InfoBar';
import DrivingLicense from '../components/Dashboard/DrivingLicense';
import DrivingPass from '../components/Dashboard/DrivingPass';
import CertificateRisk from '../components/Dashboard/CertificateRisk';
import Code95Cycle from '../components/Dashboard/Code95Cycle';
import Greetings from '../components/common/Greetings';
import {
	CERTIFICATES_ENABLED,
	CODE_95_ENABLED, DRIVING_PASS_EXPIRY_DATE_ENABLED,
	LICENSE_EXPIRY_DATE_ENABLED,
	useViewPreferences,
} from '../hooks/view-preferences';

function DashboardPage() {
	const dationUser = useDationUser();
	const { isPreferenceOptionEnabled } = useViewPreferences();
	const [{ data, isLoading }] = useApi.get(getCompanyStudents(dationUser.companyId), { archived: false }, { cachePolicy: 'no-cache' });

	return (
		<>
			<InfoBar />
			<div className="d-flex justify-content-center">
				<div className="col-12 mx-3">
					<div className="row p-3 ml-5">
						<Greetings />
					</div>
					{isLoading ? <div className="loading-spinner" /> : (
						<div className="row justify-content-start mx-5">
							{isPreferenceOptionEnabled(CERTIFICATES_ENABLED) && <CertificateRisk />}
							{ isPreferenceOptionEnabled(CODE_95_ENABLED) && <Code95Cycle students={data} />}
							{ isPreferenceOptionEnabled(LICENSE_EXPIRY_DATE_ENABLED) && <DrivingLicense students={data} />}
							{ isPreferenceOptionEnabled(DRIVING_PASS_EXPIRY_DATE_ENABLED) && <DrivingPass students={data} />}
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default DashboardPage;
