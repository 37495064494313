import React, { useEffect, useState } from 'react';
import {
	Accordion, Alert,
	Card, Modal, ModalBody, ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getDisplayName } from '../../../utils/person';
import { useDationUser } from '../../../hooks/dation-user';
import { getDateFormat, getWeekdayFormat } from '../../../utils/date';
import useApi from '../../../hooks/use-api';
import { updateEnrollment } from '../../../constants/routes';
import { sendGoogleAnalyticsEvent } from '../../../utils/googleAnalytics';
import SlotHeader from './SlotHeader';
import StudentAccordionContent from './StudentAccordionContent';
import { findExistingReservation, getCalculatedReservationAmounts, isPlanningRestricted } from '../../../utils/training';
import PlanningTooltip from '../../common/PlanningTooltip';
import ErrorMessage from '../../common/messages/ErrorMessage';

function PlannedTrainingModal({
	training,
	show,
	setShow,
	showDisenroll = false,
	handleSubmit,
}) {
	const [loaded, setLoaded] = useState(false);
	const { t } = useTranslation();
	const dationUser = useDationUser();
	const { enrollments = [], trainingReservations = [] } = training;
	const [isDisenrollmentDisabled, disableDisenrollement] = useState(false);
	const [disenrollmentSuccessMessage, setDisenrollmentSuccessMessage] = useState(null);
	const [disenrollmentErrorMessage, setDisenrollmentErrorMessage] = useState(null);
	const [studentsPerSlot, setStudentsPerSlot] = useState([]);
	const [enrollmentsWithoutSlots, setEnrollmentsWithoutSlots] = useState([]);
	const [unsubscribedStudents, setUnsubscribedStudents] = useState([]);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);

	const [, deleteEnrollment] = useApi.delete(updateEnrollment(undefined), {}, { lazy: true }); // delete does not update the url on properly, this will be updated later

	const filteredEnrollments = enrollments.filter(enrollment => dationUser.getCurrentCompanyId() === enrollment.company.id);
	const existingReservation = findExistingReservation(trainingReservations, dationUser.getCurrentCompanyId()) || {};
	const { amountPlanned, amountReserved } = existingReservation ? getCalculatedReservationAmounts(existingReservation, dationUser.getCurrentCompanyId()) : {};

	// clear data when the training changes, this prevents showing incorrect students for reservations
	useEffect(() => {
		setStudentsPerSlot([]);
		setEnrollmentsWithoutSlots([]);
	}, [training]);

	useEffect(() => {
		if(!loaded) {
			if(filteredEnrollments.length > 0) {
				const { slots } = training;
				const groupedStudents = slots.reduce((carry, slot) => {
					const slotStudents = slot?.students?.filter(student => filteredEnrollments.find(enrollment => student.id === enrollment.student.id));
					if(slotStudents?.length) {
						carry.push({ slot, students: slotStudents });
					}
					return carry;
				}, []);

				const enrolledStudentsWithoutSlots = filteredEnrollments.reduce((carry, enrollment) => {
					if(!groupedStudents.find(({ students }) => students.find(student => student.id === enrollment.student.id))) {
						carry.push(enrollment);
					}
					return carry;
				}, []);

				setStudentsPerSlot(groupedStudents);
				setEnrollmentsWithoutSlots(enrolledStudentsWithoutSlots);
			} else {
				setStudentsPerSlot([]);
				setEnrollmentsWithoutSlots([]);
			}
			setLoaded(true);
		}
	}, [training, filteredEnrollments]);

	useEffect(() => {
		setLoaded(false);
	}, [show]);

	useEffect(() => {
		disableDisenrollement(isPlanningRestricted(training));
	}, [training]);

	useEffect(() => {
		setShowSuccessAlert(!!disenrollmentSuccessMessage);
	}, [disenrollmentSuccessMessage]);

	const closeModal = () => {
		setDisenrollmentErrorMessage(null);
		setDisenrollmentSuccessMessage(null);
		disableDisenrollement(false);
		setShow(false);
	};

	const handleDisenrollmentSubmit = (enrollmentId, student) => {
		deleteEnrollment(null, updateEnrollment(enrollmentId)).then(() => {
			sendGoogleAnalyticsEvent({
				hitType: 'event',
				eventCategory: 'Enrollment',
				eventAction: 'Delete',
			});
			handleSubmit();
			setDisenrollmentSuccessMessage(t('unsubscribe.success_message', { name: getDisplayName(student) }));
			setUnsubscribedStudents([...unsubscribedStudents, student]);
		}).catch(error => {
			setDisenrollmentErrorMessage(error || t('unsubscribe.error_message'));
		});
	};

	const handleDisenroll = async student => {
		const enrollmentToDelete = enrollments.find(enrollment => enrollment.student.id === student.id);
		setDisenrollmentSuccessMessage(null);
		setDisenrollmentErrorMessage(null);

		handleDisenrollmentSubmit(enrollmentToDelete.id, student);
	};

	return (
		<Modal show={show} onHide={closeModal} size="lg">
			<Modal.Header closeButton>
				<ModalTitle>{training.name}</ModalTitle>
			</Modal.Header>
			<ModalBody>
				<ErrorMessage
					message={disenrollmentErrorMessage}
					onClose={() => {
						setDisenrollmentErrorMessage(null);
						disableDisenrollement(false);
					}}
				/>
				{showSuccessAlert
				&& (
					<Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
						{disenrollmentSuccessMessage}
					</Alert>
				)}
				<dl className="row">
					<dt className="col-3 text-primary-color">{t('trainings.date')}</dt>
					<dd className="col-8" style={{ minHeight: 24 }}>
						<div className="row">
							<div className="col-1">{getWeekdayFormat(training.start)}</div>
							<div className="col-5 pl-0 d-flex flex-row">
								<div>{getDateFormat(training.start)}</div>
							</div>
						</div>
					</dd>
					<dt className="col-3 text-primary-color">{t('trainings.location')}</dt>
					<dd className="col-9" style={{ minHeight: 24 }}>{training.city}</dd>

					<dt className="col-3 text-primary-color">{t('trainings.course')}</dt>
					<dd className="col-9" style={{ minHeight: 24 }}>{training.name}</dd>

					{amountReserved ? (
						<>
							<dt className="col-3 text-primary-color">{t('trainings.reservation')}</dt>
							<dd className="col-9">{t('trainings.reservation_amounts', {
								amountPlanned,
								amountReserved,
							})}
							</dd>
						</>
					) : null}
				</dl>
				<div className="d-flex justify-content-between">
					<h5>{t('trainings.employee_count', { count: filteredEnrollments.length })}</h5>
					{showDisenroll && isDisenrollmentDisabled && (
						<PlanningTooltip />
					)}
				</div>
				<div style={{ maxHeight: 400, overflowY: 'auto' }}>
					{studentsPerSlot.map(({ slot, students }, key) => (
						<Accordion defaultActiveKey={key + 1} key={slot.id}>
							<Card style={{ backgroundColor: 'transparent', border: 0 }}>
								<SlotHeader slot={slot} eventKey={key + 1} />
								<Accordion.Collapse eventKey={key + 1}>
									<>
										{students.map(student => (
											<StudentAccordionContent
												student={student}
												disabled={isDisenrollmentDisabled}
												showDisenroll={showDisenroll}
												onDisenroll={() => handleDisenroll(student)}
												key={student.id}
												isHidden={unsubscribedStudents.find(unsubscribedStudent => unsubscribedStudent.id === student.id)}
											/>
										))}
									</>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					))}
					{enrollmentsWithoutSlots.length > 0 && (
						<Card style={{ backgroundColor: 'transparent', border: 0 }}>
							{moment(training?.start).isAfter(moment('2021-08-05')) && (
								<div className="row border-0 ml-0 p-1" style={{ backgroundColor: 'transparent' }}>
									{t('enrollments.no_slot_student_message')}
								</div>
							)}

							<div style={{ maxHeight: '50vh' }}>
								{enrollmentsWithoutSlots.map(enrollment => (
									<StudentAccordionContent
										student={enrollment.student}
										disabled={isDisenrollmentDisabled}
										showDisenroll={showDisenroll}
										onDisenroll={() => handleDisenroll(enrollment.student)}
										key={enrollment.id}
									/>
								))}
							</div>
						</Card>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
}

export default PlannedTrainingModal;
