import { Alert } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function StudentWarnings({ warnings, onRemove }) {
	const { t } = useTranslation();
	return warnings.map(({
		id,
		name,
	}) => (
		<Alert
			variant="warning"
			className="alert-dismissible"
		>
			<button type="button" className="close" onClick={() => onRemove(id)}>
				<span aria-hidden="true" className="text-dark">×</span>
				<span className="sr-only">Close alert</span>
			</button>
			{t('trainings.enrollment_student_warning', { name })}
		</Alert>
	));
}

StudentWarnings.propTypes = {
	warnings: PropTypes.array,
	onRemove: PropTypes.func,
};
