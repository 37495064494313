import { Button } from 'react-bootstrap';
import React from 'react';
import DropdownButton from '../common/DropdownButton';

function StudentsActions({
	onAddStudent, onAddStudentsApplication, dropdownOptions,
}) {
	return (
		<div className="d-flex flex-row">
			<Button
				variant="outline-secondary"
				className="rounded-button p-1 mr-3"
				type="button"
				onClick={onAddStudent}
			>
				<span className="glyphicons glyphicons-user-plus" />
			</Button>
			{onAddStudentsApplication ? (
				<Button
					variant="outline-secondary"
					className="rounded-button p-1 mr-3"
					type="button"
					onClick={onAddStudentsApplication}
				>
					<span className="glyphicons glyphicons-education" />
				</Button>
			) : null}
			<DropdownButton options={dropdownOptions} />
		</div>
	);
}

export default StudentsActions;
