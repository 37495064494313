import React, { useCallback, useEffect, useState } from 'react';
import {
	Card, Row, Col, FormLabel, Button,
} from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfoBar from '../components/Navigation/InfoBar';
import useApi from '../hooks/use-api';
import { getCertificatesForCompany } from '../constants/routes';
import { useDationUser } from '../hooks/dation-user';
import DateInput from '../components/Form/DateInput';
import MultiSelect from '../components/common/MultiSelect';
import CcvCodes from '../utils/code95.json';
import CertificateTable from '../components/Tables/CertificateTable';
import { getDisplayName } from '../utils/person';
import { getRisk } from '../utils/training';
import CertificationRiskBadge from '../components/StudentRisk/CertificationRiskBadge';
import { compareTableSortDates } from '../utils/sort';
import useUserPreferences from '../hooks/use-user-preferences';

export default function CertificatePage() {
	const { getCurrentCompanyId } = useDationUser();
	const { t } = useTranslation();
	const { getFilters, setFilters } = useUserPreferences();
	const FILTER_NAME = 'COURSE_LIST_PAGE_FILTERS';

	const [{ data, isLoading }, fetchCertificates] = useApi.get(
		getCertificatesForCompany(getCurrentCompanyId()),
		{},
		{ lazy: true },
	);

	const [selectedCcvCodes, setSelectedCcvCodes] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [certificates, setCertificates] = useState([]);

	const search = useCallback(() => {
		fetchCertificates({
			...(selectedCcvCodes?.length > 0 ? { ccvCodes: selectedCcvCodes.map(({ value }) => value) } : {}),
			...(moment.isMoment(selectedDate) ? { certificateExpiresBeforeDate: selectedDate?.format() } : {}),
		});

		setFilters(FILTER_NAME, { selectedCcvCodes, selectedDate: moment.isMoment(selectedDate) ? selectedDate?.format() : null });
	}, [selectedCcvCodes, selectedDate]);

	useEffect(() => {
		const { selectedCcvCodes: rememberedCodes, selectedDate: rememberedDate } = getFilters(FILTER_NAME) || {};
		fetchCertificates({
			...(rememberedCodes?.length > 0 ? { ccvCodes: rememberedCodes.map(({ value }) => value) } : {}),
			...(rememberedDate ? { certificateExpiresBeforeDate: moment(rememberedDate)?.format() } : {}),
		});
		setSelectedCcvCodes(rememberedCodes);
		setSelectedDate(rememberedDate ? moment(rememberedDate) : null);
	}, []);

	useEffect(() => {
		if(data) {
			setCertificates(data.map(cert => {
				const expiryDateMoment = moment(cert.certificateExpirationDate);
				return {
					studentId: cert.student.id,
					studentName: getDisplayName(cert.student),
					trainingName: cert.training.name,
					certificateExpirationDate: expiryDateMoment.format('L'),
					sortDate: expiryDateMoment.format(),
					risk: getRisk(expiryDateMoment),
				};
			}));
		}
	}, [data]);

	const riskCell = useCallback(cellInfo => {
		const { row } = cellInfo;
		return row.original.risk
			? <CertificationRiskBadge risk={row.original.risk} /> : null;
	}, []);

	const headers = React.useMemo(() => [
		{
			Header: t('certificates.table.name'),
			accessor: 'studentName',
		},
		{
			Header: t('certificates.table.certificate'),
			accessor: 'trainingName',
		},
		{
			Header: t('certificates.table.expiryDate'),
			accessor: 'certificateExpirationDate',
			sortType: compareTableSortDates,
		},
		{
			Header: t('certificates.table.certificateRisk'),
			accessor: 'risk',
			Cell: riskCell,
		},
	], []);

	return (
		<>
			<InfoBar />
			<div className="col-sm-12 col-xl-10 mx-auto pb-4">
				<Row className="align-items-end mb-3">
					<Col xs={4}>
						<FormLabel>{t('certificates.filter.courseSelectLabel')}</FormLabel>
						<MultiSelect
							value={selectedCcvCodes}
							options={CcvCodes.map(({ ccvCode, description }) => ({
								label: `${ccvCode} - ${description}`,
								value: ccvCode,
							}))}
							onChange={setSelectedCcvCodes}
							maxItemsShown={2}
						/>
					</Col>
					<Col xs={3}>
						<FormLabel>{t('certificates.filter.expiryDateLabel')}</FormLabel>
						<DateInput onChange={newDateMoment => setSelectedDate(newDateMoment)} value={selectedDate} />
					</Col>
					<Col xs={2}>
						<Button variant="primary" onClick={search}>{t('certificates.filter.search')}</Button>
					</Col>
				</Row>
				<Card>
					<Card.Title className="m-3">{t('certificates.certificates')}</Card.Title>
					<Card.Body>
						<CertificateTable data={certificates} columns={headers} isLoading={isLoading} />
					</Card.Body>
				</Card>
			</div>
		</>
	);
}
