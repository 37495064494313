import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getDateFormat, getTimeDateFormat, getWeekdayFormat } from '../../../utils/date';

function Slots({ slots, titleClassnames, descriptionClassnames }) {
	const { t } = useTranslation();
	return (
		<>
			<dt className={`${titleClassnames} text-primary-color`}>{t('trainings.slots')}</dt>
			<dd className={descriptionClassnames} style={{ minHeight: 24 }}>
				{slots.map(slot => (
					<div className="row" key={slot.id}>
						<div className="col-1 pr-0">{getWeekdayFormat(slot.start)}</div>
						<div className="col-5 p-0 d-flex flex-row ">
							<div className="pl-1">{getDateFormat(slot.start)}</div>
							<div className="pl-2">{`${getTimeDateFormat(slot.start)}  -  ${getTimeDateFormat(slot.end)}`}</div>
						</div>
					</div>
				))}
			</dd>
		</>
	);
}

Slots.propTypes = {
	slots: PropTypes.array.isRequired,
	titleClassnames: PropTypes.string.isRequired,
	descriptionClassnames: PropTypes.string.isRequired,
};

export default Slots;
