import React from 'react';
import PropTypes from 'prop-types';

function SortComponent({ isSorted, isSortedDesc }) {
	if(!isSorted) {
		return null;
	}

	if(isSortedDesc) {
		return <span className="ml-1 glyphicons-arrow-thin-up arrow-sorted" />;
	}
	return <span className="glyphicons-arrow-thin-down arrow-sorted" />;
}

SortComponent.propTypes = {
	isSorted: PropTypes.bool.isRequired,
	isSortedDesc: PropTypes.bool,
};

export default SortComponent;
